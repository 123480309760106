import Vue from 'vue'
Vue.prototype.isDisable = (val) => {
    let isShow = true
    const btnPowerArr = JSON.parse(window.sessionStorage.getItem('headerList'))
    const btnArr = getBtn(btnPowerArr)
    // console.log('getBtn', btnArr)
    if (btnArr && btnArr.length) {
        const len = btnArr.filter(i => i.code == val)
        if (len && len.length) { isShow = false }
    }
    return isShow
}

function getBtn(arr, btnArr = []) {
    arr?.forEach(item => {
        if (item.type == 'button' || item.type == 'right')
            btnArr.push(item)
        if (item && item.childes && item.childes.length)
            getBtn(item.childes, btnArr)
    });
    return btnArr
}

/**
 * 异步请求自动设置 loading
 * 目的是用于请求接口的时候，不需要手写 loading 状态的改变
 * @param {Function} fn 异步函数
 * @param {String} loadingKey loading字段
 * @param {Object} opt 其它配置
 */
Vue.prototype.asyncWithLoading = function(fn, loadingKey = 'nLoading', opt = {}) {
    this[loadingKey] = true
    let p = fn
    if (typeof p === 'function') p = fn()
    // 延迟执行
    if (opt.delay) {
        p = Promise.all([p, new Promise(res => setTimeout(res, opt.delay))]).then(res => res[0])
    }
    return p.finally(() => (this[loadingKey] = false))
}

/**
 * 权限数据处理。将接口返回的权限菜单数据处理成渲染菜单所需数据
 * @param {Array} list 权限数组
 */
export const authDataTransform = list =>
    list.map(item => ({
        ...item,
        label: item.name,
        key: item.url
    }))

Vue.prototype.$authDataTransform = authDataTransform
