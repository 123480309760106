import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
// 缓存在localStorage中
const localStore = createPersistedState({
    paths: ['app.collapse', 'app.menuOpeneds'],
})
// 缓存在sessionStorage中
const sessionStore = createPersistedState({
    paths: ['app.menuList'],
    storage: sessionStorage,
})
Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})

const store = new Vuex.Store({
    modules,
    getters,
    plugins: [localStore, sessionStore],
})

export default store
