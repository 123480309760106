import sysMngRouter from './sysMng'

/*
 * 基础路由
 * @type { *[] }
 */
function redirectFn() {
    let menuList=JSON.parse(sessionStorage.getItem('headerList'))
    return menuList?.[0]?.key
}
export default [
    {
        path: '/',
        name: 'accountMng',
        redirect: redirectFn(),
    },
    sysMngRouter,
]
