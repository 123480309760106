<template>
    <div class="account-edit">
        <el-dialog
            title="编辑账号"
            :visible.sync="dialogVisible"
            width="800px"
            :before-close="handleClose"
            center
            :modal-append-to-body="false"
        >
            <div v-loading="vLoading">
                <div class="top">
                    <el-row>
                        <el-col :span="24"><div class="grid-content tit">基本信息</div></el-col>
                        <el-col :span="10">
                            <div class="grid-content">
                                <span>账号</span>
                                {{ topData.email }}
                            </div>
                        </el-col>
                        <el-col :span="8">

                            <div class="grid-content">
                                <span style="float: left">所在部门</span>
                                <span style="display: inline-block; width: calc(100% - 80px)">
                                    <b
                                        style="color: #152330"
                                        v-for="(item, index) in topData.departmentInfoList"
                                        :key="index"
                                    >
                                        {{ item.name }}
                                        <b v-if="index < topData.departmentInfoList.length - 1">,</b>
                                    </b>
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content">
                                <span>手机号</span>
                                {{ topData.mobile }}
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <div class="grid-content">
                                <span>姓名</span>
                                {{ topData.name }}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content">
                                <span>入职时间</span>
                                {{ topData.joinTime === null ? '' : getTime(topData.joinTime) }}
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content">
                                <span>在职/离职</span>
                                <b v-if="topData.resigned === 0">在职</b>
                                <b v-else>离职</b>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="middle">
                    <el-select
                        filterable
                        v-model="sysCode">
                        <el-option
                            v-for="item in systemList"
                            :key="item.sysCode"
                            :label="item.sysName"
                            :value="item.sysCode">
                        </el-option>
                    </el-select>
                </div>
                <div class="btm" v-if="sysCode">
                    <el-tabs v-model="activeName" class="authority-tabs">
                        <el-tab-pane label="角色" name="first">
                            <transfer
                                filterable
                                filter-placeholder="输入"
                                v-model="RData"
                                :data="LData"
                                :titles="['全部角色', '已选角色']"
                                :props="defaultProps"
                                @change="roleChange"
                            >
                                <span slot-scope="{ option }" class="span-list">
                                    <el-row>
                                        <el-col :span="option.note === null || option.note === '' ? 24 : 12">
                                            <div :title="option.name" class="grid-content">{{ option.name }}</div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div :title="option.note" class="grid-content note">{{ option.note }}</div>
                                        </el-col>
                                    </el-row>
                                </span>
                            </transfer>
                        </el-tab-pane>
                        <el-tab-pane label="数据权限" name="second" v-if="sysCode=='VR'">
							<dataAuthority :defaultData="defaultData" @treeCheck="treeCheck" ></dataAuthority>
						</el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="submit()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import transfer from './component/main'
import dataAuthority from './component/dataAuthority'
import * as API from '@/api/sysMng'
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: null
        }
    },
    components: {
        transfer,
		dataAuthority
    },
    data() {
        return {
            systemList: [],
            sysCode: null,
            defaultProps: { key: 'id', label: 'name' },
            LData: [], // 左侧数据
            RData: [], // 右侧数据
            readLoad: false,
            postData: [], // 保存时需要发送的角色合集
            topData: [],
            vLoading: false,
			activeName: 'first',
			defaultData:{
				departmentList: [],
				defaultDepartment: []
			},
			defaultDepartmentL:[]
        }
    },
    watch: {
        'sysCode': {
            handler(newV){
                if(newV) this.getDetail()
            }
        }
    },
    mounted() {
        //获取详情信息
        this.getDetail()
        //获取系统
        this.getSystemListFn()
    },
    methods: {
        handleClose() {
            // 关闭弹窗
            this.$emit('changeDialog', false)
        },
        getDetail() {
            // 获取账号详情
            const params = { id: this.id, depart: true }
            API.getAccountDetail(params)
                .then(res => {
                    this.topData = res.data
                    res.data.roleList.forEach(item => {
                        this.RData.push(item.id)
                        this.postData.push(item.id)
                    })
                    //获取数据权限
                    this.departmentGetTreeFn()
                    if(!this.sysCode) return
                    this.getAllRoleFn()
                })
        },
        getAllRoleFn(){
            API.getAllRole({sysCode: this.sysCode}).then(res1 => {
                this.LData = res1
            })
        },
        getSystemListFn(){
            API.getSystemList().then(res=>{
                this.systemList = res|| []
            })
        },
		// 获取账号数据权限
        departmentGetTreeFn(){
			let params = {
				isShowEmp: this.defaultData.isShowEmp,
				email: this.topData.email || null
			}
			API.departmentGetTree(params).then(res => {
				this.defaultData.departmentList = res && res.name ? [res] : []
				this.defaultData.defaultDepartment = res && res.hitDept && res.hitDept.length ? res.hitDept : []
				this.defaultDepartmentL = res && res.hitDept && res.hitDept.length ? res.hitDept : []
			})
		},
		treeCheck(checkKeys){
			this.defaultDepartmentL = checkKeys.filter(item => {return item})
		},
        submit() {
            this.saveDataAuth()
            this.saveAccountMsg()
        },
        saveDataAuth(){
            const params = {
                email: this.topData?.email,
                departmentIds: this.defaultDepartmentL
            }
            API.departmentSaveDataAuth(params)
        },
        saveAccountMsg(){
            const params = {
                userId: this.id,
                roleIds: Array.from(new Set(this.postData))
            }
            API.editAccount(params).then(() => {
                this.$message({type: 'success', message: '保存成功'})
                // 提交成功关闭弹窗
                this.$emit('changeDialog', false)
            })
        },
        roleChange(key) {
            // 获取穿梭框移动的值
            this.RData = [] // 先清空右侧
            const keys = Array.from(new Set(key))
            this.postData = keys // 给需要发送后台的参数赋值
            keys.forEach(item => {
                // 给右侧重新赋值
                this.RData.push(item)
            })
        },
        getTime(val) {
            const date = new Date(val)
            const y = date.getFullYear()
            let m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            let d = date.getDate()
            d = d < 10 ? '0' + d : d
            return y + '-' + m + '-' + d
        }
    }
}
</script>
<style lang="scss" scoped>
.account-edit {
    .tit {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0 !important;
    }
    .top {
        background: #fafafa;
        padding: 20px;
        color: #152330;
        .grid-content {
            margin-top: 12px;
            span {
                display: inline-block;
                width: 65px;
                color: #576877;
            }
            span:nth-child(1) {
                text-align: right;
                padding-right: 10px;
            }
        }
    }
    .middle{
        margin: 10px 20px 0;
    }
    .btm {
        padding: 10px 20px;
        .tit {
            margin-bottom: 20px;
        }
		.authority-tabs{
			/deep/ .el-tabs__nav-wrap::after{
				background: #fff;
			}
			/deep/ #tab-first{
				padding-right: 0;
			}
		}
        .span-list {
            span {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
</style>
<style lang="scss">
.account-edit {
    .btm {
        .grid-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .note {
            color: #a3a9b7;
            padding-left: 15px;
        }
    }
    .el-dialog__body {
        height: 520px;
        overflow-y: scroll;
        box-sizing: border-box;
    }
    .el-transfer-panel__filter .el-input__inner {
        border-radius: 0;
    }
    .el-transfer {
        border: 1px solid #eaeef2;
        padding: 5px 10px 10px 10px;
    }
    .el-transfer-panel .el-transfer-panel__header {
        background: #fff;
        border: none;
    }
    .el-transfer-panel__body {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        padding-top: 10px;
        height: 300px;
    }
    .el-transfer-panel {
        border: 1px solid #fff;
        overflow: visible;
        width: 280px;
    }
    .el-transfer-panel:nth-child(3) {
        float: right;
    }
    .el-transfer-panel__filter {
        margin: 0 15px;
    }
    .el-transfer-panel__list.is-filterable {
        height: 268px;
    }
    .el-transfer__buttons {
        margin-left: 20px;
        margin-top: 50px;
        button {
            display: block;
            margin: 10px 0;
            padding: 0;
            width: 24px;
            height: 24px;
        }
        button:nth-child(1) {
            transform: translateY(34px);
        }
        button:nth-child(2) {
            transform: translateY(-34px);
        }
    }
    .span-msg {
        color: #a3a9b7;
        margin-left: 40px;
        padding-right: 10px;
    }
    .el-checkbox {
        margin-right: 15px;
    }
    .el-transfer-panel__item {
        height: 40px;
    }
    .el-transfer-panel__item.el-checkbox .el-checkbox__label {
        line-height: 40px;
        overflow: visible;
    }
    .el-transfer-panel__item .el-checkbox__input {
        top: 13px;
    }
}
</style>
