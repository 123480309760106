<template>
    <div class="welcome">
        <div class="left flex">
            <div class="top flex">
                <div class="hello"><img src="../assets/images/HELLO@2x.png" alt=""></div>
                <div class="name">{{userInfo.name}}</div>
            </div>
            <div class="center"><img src="../assets/images/better.png" alt=""></div>
            <div class="btm">愿你成就更好的自己</div>
        </div>
        <div class="right"></div>
    </div>
</template>
<script>
export default {
    name: 'welcome',
    props: ['userInfo'],
}
</script>
<style lang="scss">
    .welcome {
        display: flex;
        width: 100vw;
        height: 92vh;
        min-width: 1440px;
        min-height: 840px;
        background: url(../assets/images/welcomeBg.png);
        background-size: 100%;

        .flex {
            display: flex;
        }

        .left {
            flex: 2;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .top {
                justify-content: center;
                align-items: center;

                img {
                    width: 140px;
                }

                .name {
                    margin-left: 40px;
                    color: #152330;
                    font-size: 40px;
                    font-weight: 600;
                }
            }

            .center {
                height: 50%;

                img {
                    width: 330px;
                }
            }

            .btm {
                font-size: 30px;
                font-weight: 400;
                color: #15A29E;
            }
        }

        .right {
            flex: 3;
        }
    }

</style>
