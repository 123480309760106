<template lang='pug'>
//- TODO: 事件使用v-on代替，类似于v-bind
//- pug 复用
mixin _action
    .actions
        el-button(v-if='queryList.length > 0', icon='el-icon-refresh-right', @click='reset') 重置
        el-button(v-if='queryList.length > 0', icon='el-icon-search', @click='search', type='primary') 查询
        //- 头部插槽
        .query-btn
            slot(name='query-btn')

//- 正文
.n-table
    //- 检索渲染
    .query-type-box(v-if='queryList.length && queryStyle == "2"')
        NTableQueryStyle2(
            :query-list='queryList',
            v-model='query',
            :default-attrs='defaultAttrs',
            @formChange='formChange',
            @dateBlur='dateBlur',
            @search='search'
        )
            .ntable-block(style='height: 10px')
            +_action
    .query-box.basic-wrap(v-else-if='queryList.length')
        .query(v-for='i in queryList', :key='i.key')
            .tit {{ i.label }}:
            el-date-picker.form-item.datetimerange(
                v-if='i.form === "date"',
                v-model='query[i.key]',
                v-bind='Object.assign(defaultAttrs.date(i), i.attrs)',
                @blur='dateBlur',
                @change='e => formChange(i, e)'
            )
            el-select.form-item(
                v-loading='i.loading',
                v-else-if='i.form === "select"',
                v-bind='Object.assign(defaultAttrs.select(i), i.attrs)',
                v-model='query[i.key]',
                @change='e => formChange(i, e)',
                @visible-change='v => querySelectVisibleChange(v, i)',
                @clear='query[i.key] = null'
            )
                el-option(
                    v-for='item in i.options',
                    v-if='(item.key || item.key === 0) && item.label',
                    :key='item.key + item.label',
                    :label='item.label',
                    :value='item.key'
                    popper-class="popperClass"
                )
            //- 限制输入为数字
            //- TODO: 此功能实现不够优雅
            el-input.form-item(
                v-else-if='i.number',
                oninput='value=value.replace(/[^\\d]/g, \'\')',
                v-model.trim='query[i.key]',
                v-bind='Object.assign(defaultAttrs.input(i), i.attrs)',
                @keyup.enter.native='search'
            )
            el-input.form-item(
                v-else,
                v-model.trim='query[i.key]',
                v-bind='Object.assign(defaultAttrs.input(i), i.attrs)',
                @keyup.enter.native='search'
            )
        +_action

    slot(name='body')
    .basic-wrap
        //- 表格渲染
        el-table(
            v-bind='$attrs',
            v-on='$listeners',
            border,
            style='width: 100%',
            :header-cell-style='headerCellStyle',
            ref='table',
            :data='list'
        )
            //- 头部插槽
            slot(name='table-header')
            el-table-column(
                v-for='i in columns',
                :key='i.key',
                :label='i.label',
                :prop='i.key',
                v-bind='Object.assign(defaultAttrs.column(i), i.attrs)'
            )
                template(slot-scope='scope')
                    //- 动态渲染vue
                    .n-dynamic(v-if='i.component')
                        component(:is='i.component', :value='scope.row[i.key]', :item='scope.row')
                    //- 按钮处理
                    .n-actions(v-else-if='i.actions')
                        el-button(
                            type='text',
                            v-for='action in i.actions',
                            :key='action.name',
                            @click.stop='$emit(action.fnKey, scope)'
                        ) {{ action.name || scope.row[i.key] }}
                    //- 合并属性
                    .n-combine(v-else-if='i.combine', :style='i.style')
                        template(v-for='(item, index) in i.combine')
                            //- 改成 v-html ，这样在配置时灵活度更高
                            span(v-if='item.filter', v-html='item.filter(scope.row[item.key], scope.row)')
                            span(v-else) {{ scope.row[item.key] | valueFilter }}
                    //- 渲染图片 默认图片宽度100
                    .n-img(v-else-if='i.img', :style='i.style', alt="")
                        img(:src='scope.row[i.key]', :width='i.img.width || 100', alt="")
                    //- 正常渲染
                    span.n-cell(v-else, :style='i.style')
                        span(v-if='i.filter', v-html='i.filter(scope.row[i.key], scope.row, scope)')
                        span(v-else) {{ scope.row[i.key] | valueFilter }}
            //- 尾部插槽
            slot(name='table-footer')

        //- 分页
        el-pagination.is-background(
            v-if='pagination',
            :current-page='pagination.page',
            :page-size='pagination.pageSize',
            :page-sizes='pageSizes',
            :total='pagination.totalElements',
            @size-change='size => $emit("size-change", size)',
            @current-change='page => $emit("current-page-change", page)',
            layout='->, prev, pager, next, total, jumper, sizes'
        )
</template>

<script>
import NTableQueryStyle2 from './NTableQueryStyle2'
export default {
    name: 'n-table',
    components: { NTableQueryStyle2 },
    props: {
        /**
         * 检索风格
         * 1: 平铺
         * 2: 单个占满 X 轴
         */
        queryStyle: {
            type: [String, Number],
            default: 1,
        },
        // 检索下拉框、时间选择器选中后是否立即执行查询方法
        searchOnSelected: {
            type: [String, Boolean],
            default: true,
        },
        // 表格显示配置
        columns: Array,
        // 表格数据
        list: Array,
        // 分页数据
        pagination: Object,
        borderTable: Boolean,
        // TODO: 考虑删掉
        extraQueryList: Array,
    },
    created() {
        this.setQuery()
    },
    data() {
        return {
            // 日期 picker 的最小时间
            minDateTimestamp: null,
            // 默认属性
            defaultAttrs: {
                column: () => ({
                    'show-overflow-tooltip': true,
                    align: 'center',
                }),
                date: i => {
                    const option = {
                        clearable: true,
                        type: 'datetimerange',
                        startPlaceholder: '开始时间',
                        endPlaceholder: '结束时间',
                        size: 'small',
                        placeholder: `请选择${i.label}`,
                        pickerOptions: {
                            // 日期 onPick
                            onPick: ({ maxDate, minDate }) => {
                                this.minDateTimestamp = minDate.getTime()
                                if (maxDate) this.minDateTimestamp = 0
                            },
                        },
                    }
                    // 配置了选择器的动态限定日期范围 或 禁选未来日期
                    if (i.dateMaxRange || i.disableFuture) {
                        option.pickerOptions.disabledDate = theDate => this.disabledByDay(theDate, i)
                    }
                    // 配置了日期选择器禁止允许跨月
                    if (i.disableCrossMonth) option.pickerOptions.disabledDate = this.disableCrossMonth
                    return option
                },
                select: i => {
                    const option = {
                        clearable: true,
                        size: 'small',
                        placeholder: `请选择${i.label}`,
                    }
                    if (i.attrs?.remote) option.remoteMethod = e => this._remoteMethod(e, i)
                    return option
                },
                radio: () => ({
                    size: 'small',
                }),
                checkbox: () => ({
                    size: 'small',
                }),
                input: i => ({
                    clearable: true,
                    size: 'small',
                    placeholder: `请输入${i.label}`,
                }),
            },
            // 检索表单绑定
            query: {},
            defaultQuery: {},
            // TODO: 考虑换成 el-table-column 原生 formatter
            // 缓存值过滤器
            valFormatters: {},
            // 检索表单列表数据
            queryList: [],
            // 分页
            pageSizes: [10, 20, 50, 100],
        }
    },
    watch: {
        columns() {
            this.setQuery()
        },
        extraQueryList() {
            this.setQuery()
        },
    },
    filters: {
        valueFilter(val) {
            if (val === 0) return 0
            return val || '-'
        },
    },
    methods: {
        /**
         * 日期关闭时，初始化minDateTimestamp
         */
        dateBlur() {
            this.minDateTimestamp = null
        },
        /**
         * date-picker 日期选择器动态范围选择禁用
         * @param {String} theDate pickerOptions.disabledDate 的第一个参数
         * @param {String} option  配置
         * @param {Number} option.dateMaxRange 天数范围
         * @param {Number} option.disableFuture 天数范围
         */
        disabledByDay(theDate, { dateMaxRange, disableFuture }) {
            const oneDay = 3600 * 1000 * 24
            const current = theDate.getTime()
            // 禁用未来日期
            if (disableFuture && current > Date.now()) return true
            if (!dateMaxRange || !this.minDateTimestamp) return false
            // 时间范围最大可选择30天,最晚时间为今天
            const lastMonthBegin = this.minDateTimestamp - dateMaxRange * oneDay
            const lastMonthEnd = this.minDateTimestamp + dateMaxRange * oneDay
            return current < lastMonthBegin || current > lastMonthEnd
        },
        /**
         * date-picker 日期选择器禁止跨月
         * @param {String} theDate pickerOptions.disabledDate 的第一个参数
         */
        disableCrossMonth(theDate) {
            if (!this.minDateTimestamp) return false
            let date = new Date(this.minDateTimestamp)
            // 大于或者小于本月的日期被禁用
            return date.getMonth() > new Date(theDate).getMonth() || date.getMonth() < new Date(theDate).getMonth()
        },
        // 远程搜索收起下拉框时恢复初始数据
        querySelectVisibleChange(visible, opt) {
            if (!visible && opt.attrs?.remote) {
                this._remoteMethod('', opt)
            }
        },
        headerCellStyle() {
            let borderType = this.borderTable
                ? 'background:#F9F9F9;color: #152330;borderBottom:0;'
                : 'background:#FAFAFA;color: #152330;border:0;'
            return borderType
        },
        // 下拉框change事件
        formChange(item, e) {
            this.$emit('formChange', item, e)
            // 需要配置选中时搜索，才会执行
            if (this.searchOnSelected && item.searchOnChange) this.search()
            // 如果配置了远程搜索，检索后，重置下拉数据
            if (item.remoteMethod && item.attrs?.remote && item.form != 'checkbox') {
                // fix-selected-error: 下拉数据初始化之后，如果当前值不在初始化的100条数据之内，会导致当前选中值丢失
                item.selectedOptions = item.options.filter(i => (Array.isArray(e) ? e.includes(i.key) : i.key == e))
                this._remoteMethod(item.remoteMethodQueryInit, item)
            }
            // 如果配置了联动搜索
            if (item.remoteLink) {
                // 找到联动检索配置
                const i = this.queryList.find(i => i.key == item.remoteLink)
                if (!i) console.warn('[n-table warn] 联动检索key未找到')
                else {
                    this._remoteMethod(i.remoteMethodQueryInit, i).then(() => {
                        // 联动后清空value
                        if (i.form == 'checkbox') this.query[i.key] = []
                        else this.query[i.key] = null
                    })
                }
            }
        },
        /**
         * 设置检索数据
         * target: 要修改配置的key
         * config: 要修改的配置
         * value: {target: string, label: string}
         */
        setQueryData({ target, config, value } = { config: 'options', value: [] }) {
            this.queryList.find(i => i.key == target)[config] = value
        },
        // 重置
        reset() {
            const queryArr = Object.keys(this.query)
            if (queryArr.length === 0) return
            this.setQuery()
            this.query = {
                ...this.defaultQuery,
            }
            this.search()
        },
        // 获取检索数据
        getSearchData() {
            const queryArr = Object.keys(this.query).filter(key => this.query[key] !== null && this.query[key] !== '')
            const query = queryArr.reduce((acc, cur) => {
                const item = this.queryList.find(i => i.key == cur)
                const val = this.valFormatters[cur](this.query[cur], item)
                if (Object.prototype.toString.call(val) === '[object Object]') Object.assign(acc, val)
                else acc[cur] = val
                return acc
            }, {})
            return query
        },
        // 拼装检索对象，通知父组件
        search() {
            this.$emit('search', this.getSearchData())
        },
        // 设置检索数据
        setQuery() {
            this.queryList = []
            this.columns.forEach(i => {
                if (i.query) this.setToData(i)
                if (i.combine) i.combine.forEach(this.setToData)
            })
            // TODO: 考虑删掉
            this.extraQueryList &&
                this.extraQueryList.forEach(i => {
                    this.setToData(i)
                })
        },
        // 设置检索数据
        setToData(i) {
            if (!i.query) return
            const defaultQuery = {
                searchOnChange: true,
                loading: false,
                valFormatter: val => val,
                // 如果配置了远程数据, 默认初始化检索数据是'', 可以配置初始值 remoteMethodQueryInit
                remoteMethodQueryInit: '',
                // fix-selected-error
                selectedOptions: [],
            }
            if (Array.isArray(i.query)) {
                i.query.forEach(query => this.setToData({ ...i, query }))
                return
            }
            i = { key: i.key, label: i.label, ...defaultQuery, ...i.query }
            // 兼容老板本的 getDatasFunction 配置
            if (i.getDatasFunction) i.remoteMethod = i.getDatasFunction
            if (typeof i.remoteMethod === 'function') this._remoteMethod(i.remoteMethodQueryInit, i)
            // select 增加 options 默认值，解决下拉数据不响应问题
            if (['select', 'checkbox'].includes(i.form) && !i.options) i.options = []
            // 增加多选时，全部逻辑

            if (i.form == 'checkbox') i.checkAll = i.defaultValue ? false : true
            const key = i.key
            this.valFormatters[key] = i.valFormatter
            this.queryList.push(i)
            let defaultValue =
                i.defaultValue || i.defaultValue === 0 ? i.defaultValue : i.form == 'checkbox' ? [] : null
            this.$set(this.query, key, defaultValue)
            this.$set(this.defaultQuery, key, defaultValue)
        },
        // 远程数据
        _remoteMethod(query, i) {
            i.loading = true
            const p = i.remoteMethod(query, this.query)
            // 解决未返回 Promise 问题
            if (p && p.then) {
                return p
                    .then(res => {
                        if (!Array.isArray(res)) {
                            console.warn('[n-table warn] 获取远程数据有误，请返回数组')
                            console.warn({ res })
                            i.options = []
                        } else {
                            i.options = res
                                .filter(opt => !i.selectedOptions.some(j => opt.key == j.key))
                                .concat(i.selectedOptions)
                        }
                    })
                    .finally(() => (i.loading = false))
            } else {
                i.loading = false
                return Promise.resolve()
            }
        },
    },
}
</script>

<style scoped lang="stylus">
.n-table {
    .basic-wrap {
        padding 16px
        background-color #fff
        margin-bottom 10px
    }
    .query-box {
        display flex
        align-items center
        flex-flow row wrap
        .query {
            display flex
            align-items center
            margin 0 20px 10px 0
            /deep/ .el-select__tags {
                .el-tag:first-child {
                    .el-select__tags-text {
                        display inline-block
                        max-width 130px
                        overflow hidden
                        text-overflow ellipsis
                        white-space nowrap
                    }
                    .el-tag__close.el-icon-close {
                        margin-bottom 10px
                    }
                }
            }
            .tit {
                min-width 70px
                font-size 14px
                color #606266
                flex none
                text-align right
            }
            .form-item {
                width 180px
                margin-left 10px
                &.daterange {
                    width auto
                }
                .popperClass{
                    max-width:500px
                }
            }
            .datetimerange, .daterange {
                width 340px
            }
        }
    }
    /deep/ .el-table__header {
        .cell {
            font-weight 700
            font-size 14px
        }
    }
    .actions {
        display flex
        margin-top 10px
        width 100%
        .query-btn {
            margin-left auto
        }
    }
    .el-pagination {
        margin 30px 0 20px
    }
}
</style>

