import { getAllRole, getDepartList, getCity, tenantList } from '@/api/sysMng'
export default [
    {
        label: '员工姓名',
        key: 'name',
        attrs: {
            width: '110px'
        },
        query: {
            label: '员工姓名'
        }
    },
    {
        label: '员工账号',
        key: 'email',
        query: {
            label: '员工账号'
        }
    },
    {
        label: '员工手机号',
        key: 'mobile',
        query: {
            label: '手机号',
            key: 'mobile'
        }
    },
    {
        label: '企业',
        key: 'tenantName',
        query: {
            label: '角色',
            key: 'roleIds',
            form: 'select',
            searchOnChange: false,
            attrs: {
                multiple: true,
                filterable: true,
                collapseTags: true,
                clearable: true,
            },
            remoteMethod(name = null) {
                return getAllRole({ name }).then(list => list.map(i => ({ key: i.id, label: i.name })))
            }
        }
    },
    {
        label: '所在部门',
        key: 'departmentInfoList',
        filter: v =>{
            if (!v && v != 0) return '-'
            return v.map(item =>item?.pathList?.map(itm =>itm?.name)).toString()
        },
        ellipsis: true,
        query: {
            label: '企业',
            key: 'tenantKeys',
            form: 'select',
            searchOnChange: false,
            attrs: {
                multiple: true,
                filterable: true,
                collapseTags: true,
                clearable: true,
            },
            remoteMethod(name = null) {
                return tenantList({ name }).then(list => list.map(i => ({ key: i.tenantKey, label: i.tenantName })))
            }

        },
    },
    {
        label: '所在地区',
        key: 'city',
        query: {
            label: '所在部门',
            key: 'departIds',
            form: 'select',
            searchOnChange: false,
            attrs: {
                multiple: true,
                filterable: true,
                collapseTags: true,
                clearable: true,
            },
            remoteMethod(name = null) {
                return getDepartList({ name }).then(list => list.map(i => ({ key: i.id, label: i.name })))
            }
        },

    },
    {
        label: '角色',
        key: 'roleList',
        filter: v =>{
            if (!v && v != 0) return '-'
            return v.map(item=>item.name).toString()
        },
        query: {
            label: '所在地区',
            key: 'cities',
            form: 'select',
            searchOnChange: false,
            attrs: {
                multiple: true,
                filterable: true,
                collapseTags: true,
                clearable: true,
            },
            remoteMethod(name = null) {
                return getCity({ name }).then(list => list.map(i => ({ key: i.id, label: i.name })))
            }
        }
    },
    {
        label: '在职/离职',
        key: 'resigned',
        filter: v =>{
            if (!v && v != 0) return '-'
            return {0: '在职', 1: '离职'}[v]
        },
        query: {
            form: 'select',
            searchOnChange: false,
            options: [
                {
                    key: '0',
                    label: '在职'
                },
                {
                    key: '1',
                    label: '离职'
                }
            ]
        }
    }
]
