<template>
    <div class="header flex">
        <h1 class="logo flex">
            <img src="../../assets/images/logo.png" alt="系统管理" />
            <span class="title">权限系统</span>
        </h1>
        <div class="user-info flex">
            <div class="img-box"><img class="header-pic" :src="userInfo.avatarUrl" alt="" /></div>
            <div class="name">
                <div class="box">
                    {{ userInfo.name }}
                    <i class="el-icon-caret-bottom"></i>
                </div>
            </div>
            <div class="logout" @click="logout">
                <img class="default" src="../../assets/images/logout.png" alt="" />
                <img class="hover" src="../../assets/images/logout-hover.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { toFeishu } from '@/utils/index'
    import { logout } from '@/api/home'
    export default {
        props: ['userInfo'],
        created() {
            this.defaultActive = this.$route.path
        },
        computed: {
            ...mapState('app', ['menuList', 'currentModule']),
            defaultActive: {
                set(path) {
                    const active = '/' + path.split('/')[1] || '/'
                    this.$store.commit('app/SET_CURRENT_MODULE', active)
                },
                get() {
                    return this.currentModule
                },
            },
        },
        watch: {
            $route() {
                this.defaultActive = this.$route.path
            },
        },
        methods: {
            //退出登录
            logout() {
                toFeishu()
                logout({ id: this.userInfo.id })
            },
        }
    }
</script>
<style lang='scss' scoped >
    .flex {
        display: flex;
    }
    .header {
        background: #15a29e;
        height: 60px;
        margin: 0;
        padding: 0 8px;
        align-items: center;
        justify-content: space-between;
        .logo {
            align-items: center;
            font-size: 18px;
            color: #fff;
            max-width: 220px;
            margin-right: 20px;
            transition: 0.35s;
            img {
                width: 40px;
                margin: 0 10px;
                transition: 0.35s;
            }
            .title {
                font-weight: bold;
                letter-spacing: 2px;
                width: 140px;
                transition: 0.35s;
                overflow: hidden;
                span {
                    width: 140px;
                    display: inline-block;
                }
            }
        }
        .el-menu {
            display: flex;
            overflow: auto;
            margin-right: 20px;
            .el-menu-item {
                opacity: 0.77;
                transition: 0.35s;
            }
            .is-active {
                opacity: 1;
                font-weight: bold;
            }
        }
        .message {
            align-self: stretch;
            width: 40px;
            margin-left: auto;
            font-size: 20px;
            color: #fff;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                background-color: rgb(17, 130, 126);
            }
        }
        .user-info {
            position: relative;
            align-items: stretch;
            margin-left: 20px;
            flex: none;
            align-self: stretch;
            .img-box {
                display: flex;
                align-items: center;
                .header-pic {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
            }
            .name {
                color: #fff;
                margin-left: 8px;
                max-width: 150px;
                transition: 0.35s;
                overflow: hidden;
                display: flex;
                align-items: center;
                .box {
                    flex: none;
                }
            }
            &:hover {
                .logout {
                    visibility: visible;
                    opacity: 1;
                }
            }
            .logout {
                visibility: hidden;
                position: absolute;
                z-index: 999;
                opacity: 0;
                cursor: pointer;
                top: 50px;
                right: 0;
                transition: all 0.3s;
                img {
                    width: 100px;
                }
                .hover {
                    display: none;
                }
                &:hover {
                    .default {
                        display: none;
                    }
                    .hover {
                        display: inline-block;
                    }
                }
            }
        }
    }
</style>
