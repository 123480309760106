<template lang="pug">
//- 设计：https://lanhuapp.com/url/hsH8Q-F5dwC
.item(:class='[collapse ? "close" : "open"]')
    el-menu-item(:index='item.url')
        i.iconfont(:class='item.iconUrl || "icon-butongyigerenxinxishiyong"')
        span.tit(slot='title') {{ item.name }}
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'menu-item',
    props: ['item', 'defaultActive'],
    computed: {
        ...mapState('app', ['collapse']),
    },
}
</script>

<style scoped lang="stylus">
.item {
    .menu-icon {
        width 20px
        display inline-block
        img {
            width 15px
            display block
        }
    }
    .iconfont {
        font-size 14px
        margin-right 8px
        color #333
        &.icon-butongyigerenxinxishiyong {
            color red
        }
    }
    .el-menu-item {
        display flex
        align-items center
        border-right 2px solid transparent
        height 50px
        line-height 50px
        position relative
        .tit {
            font-size 14px
            position relative
            z-index 1
            color #4E5969
            transition 0.35s
        }
        .iconfont {
            position relative
            z-index 1
            color #4E5969
            transition 0.35s
        }
    }
    .el-submenu {
        .el-menu-item {
            min-width var(--menu-open-width)
            .tit {
                color #999
            }
        }
    }
    /deep/ {
        .el-submenu__title {
            height 45px
            line-height 45px
            color #474F5B
        }
    }
}
// 关闭状态样式修改
.close /deep/ {
    .el-menu-item {
        border-right 0
    }
    .el-submenu__title {
        padding 0 18px !important
        span, .el-submenu__icon-arrow {
            visibility hidden
        }
    }
    .el-tooltip {
        padding 0 18px !important
    }
}
// 开启状态，修改菜单的hover、active样式
.open {
    hover() {
        &::before {
            content ''
            position absolute
            left 6px
            right 6px
            top 0
            bottom 0
            border-radius 3px
            background #EFFAFA
            transform translateX(-10px)
            opacity 0
            transition 0.4s cubic-bezier(0.29, 1, 0.29, 1)
            will-change transform, opacity
            transform-origin left center
        }
        &:hover, &:focus {
            background-color unset
            &::before {
                background #F6FCFC
                transform translateX(0)
                opacity 1
            }
        }
    }
    .el-submenu {
        /deep/ .el-submenu__title {
            hover()
            span, .iconfont {
                position relative
            }
            i {
                z-index 1
            }
        }
    }
    .el-menu-item {
        hover()
        &.is-active {
            .iconfont, .tit {
                color #06B4AD
            }
            &::before {
                transform translateX(0)
                opacity 1
            }
        }
    }
}
</style>
