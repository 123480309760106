// axios
import request from '@/utils/request'

// 飞书扫码拿到code登录
// export function codeLogin(data) {
//     return post(api.codeLogin, data)
// }
export function codeLogin(data) {
    return request({
        url: '/login',
        data
    })
}

// 登录后根据id拿到用户权限
export function userAuth(data) {
    return request({
        url: '/auth-api/user/auth/list',
        data
    })
}

// 登录后根据id拿到用户信息
export function userInfo(data) {
    return request({
        url: '/user-api/user/get/by/id',
        data
    })
}

// 角色获取
export function roleList(data) {
    return request({
        url: '/auth-api/role/list',
        data
    })
}

// 退出登录
export function logout(data) {
    return request({
        url: 'logout',
        data
    })
}
