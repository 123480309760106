const required = { required: true, message: '请输入', trigger: 'blur' }
export const config = [
    {
        label: '名称',
        key: 'name',
        rules: [required],
    },
    {
        label: '路由',
        key: 'url',
    },
    {
        label: '接口地址',
        key: 'serviceUrl'
    },
    {
        label: '图标',
        key: 'iconUrl',
    },
    {
        label: '选中图标',
        key: 'iconUrlAct',
        placeholder: '使用 iconfont 时无需配置',
    },
    {
        label: '公共',
        key: 'isCommon',
        formType: 'radio',
        options: [
            {
                value: true,
                label: '是'
            },
            {
                value: false,
                label: '否'
            }
        ]
    },
    {
        label: '类型',
        key: 'type',
        formType: 'select',
        options: [],
    },
    {
        label: 'Code',
        key: 'code',
        rules: [required],
    },
    {
        label: '排序',
        key: 'sort',
    },
    {
        label: '父级ID',
        key: 'parentId',
    },
    {
        label: 'level',
        key: 'level',
    }
]
