<template>
    <div class="app-container">
        <el-container style="min-height: 100%" v-if='userInfo'>
            <el-header style="padding: 0; position: fixed; top: 0; width: 100%; z-index: 10">
                <Header :userInfo="userInfo" />
            </el-header>
            <vWelcome v-if="!menuList.length" :userInfo="userInfo" />
            <el-container class="app-content-container" v-if="menuList.length">
                <VMenu />
                <el-main :class="[collapse ? 'close' : 'open']">
                    <div class="layout-content">
                        <keep-alive>
                            <router-view/>
                        </keep-alive>
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Header from '@/components/Header'
import VMenu from '@/components/VMenu'
import vWelcome from '@/components/welcome'
import { userAuth, userInfo, codeLogin } from '@/api/home'
import { getUrlVars, toFeishu } from '@/utils/index'
import { mapState } from 'vuex'
export default {
    name: 'AppLayout',
    props: ['type'],
    components: { Header, VMenu, vWelcome },
    computed: {
        ...mapState('app', ['collapse', 'menuList']),
    },
    data() {
        return {
            userId: null,
            userInfo: {
                name: null,
                id: null,
            },
        }
    },
    mounted() {
        let userInfo = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo'))
        if (userInfo && this.menuList?.length) {
            this.userInfo = userInfo
            return false
        }
        //本地开发
        if (process.env.VUE_APP_MODE == 'local') {
            //'本地')
            this.development()
        } else {
            //'非本地')
        this.production()
        }
    },
    methods: {
        // 本地登录
        development() {
            let userInfo = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo'))
            if (userInfo) {
                this.userId = userInfo.id
                this.userInfo = userInfo
                this.getUserAuth()
            }
            if (!this.menuList?.length) {
                this.getUserAuth()
            }
        },
        // 测试或者线上
        production() {
            const code = getUrlVars(window.location.href)?.code
            let userInfo = this.$cookie.get('userInfo') && JSON.parse(this.$cookie.get('userInfo'))
            if (code) this.getCodeLogin(code)
            else {
                this.userId = userInfo?.id
                if (this.userId) this.getUserInfo()
                else toFeishu()
            }
        },
        //获取用户权限
        getUserAuth() {
            if (!this.userId) {
                toFeishu()
            }
            let param = { userId: this.userId, sysCode: 'AUTH-CENTER' }
            userAuth(param).then(res => {
                if (res && res.data) {
                    this.$store.commit('app/SET_MENU_LIST', res.data)
                    this.$store.dispatch('userInfo/setUserInfo', this.userInfo)
                    //跳转有权限的第一个路由地址
                    if(res.data?.length){
                        this.$router.push({path: res.data?.[0]?.url})
                    }
                }
                this.$forceUpdate()
            })
        },
        //根据飞书返回的code获取用户信息
        getCodeLogin(code) {
            let param = { code }
            codeLogin(param)
                .then(res => {
                    if (res && res.data && res.data.id) {
                        this.$cookie.set('userInfo', JSON.stringify(res.data), { expires: 1 })
                        localStorage.setItem('userInfoLogin', res.data)
                        this.userId = res.data.id
                        this.getUserInfo()
                    }
                })
                .catch(() => {
                    toFeishu()
                })
        },
        //获取用户信息
        getUserInfo() {
            let param = { id: this.userId }
            userInfo(param).then(res => {
                if (res && res.data) {
                    this.userInfo = res.data
                    window.sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
                    this.$store.dispatch('userInfo/setUserInfo', this.userInfo)
                    this.getUserAuth()
                }
            })
        },
    }
}
</script>
<style lang="stylus" scoped>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to{
    transform: translateX(100vw);
    opacity: 0;
}
.layout-content {
    min-height 100%
}
.app-container {
    height 100%
    position relative
    .app-content-container {
        height calc(100% - 60px)
        margin-top 60px
    }
    .el-main {
        position relative
        background-color #f7f9fa
        padding 16px 14px 0
        transition 0.35s
        padding-left calc(var(--menu-open-width) + 20px)
        &.close {
            padding-left calc(var(--menu-close-width) + 20px)
        }
    }
}
.right-icon-wrap {
    .knowledIcon {
        position relative
        .unread {
            position absolute
            color #f23c3c
            top -12px
            font-size 14px
            left -5px
        }
    }
    .highlight {
        background-color: #22BDB9;
        color: #fff;
        &:hover {
            color: #fff;
            background-color: #15A29E;
        }
    }
    .imIcon {
        position relative
        .unread {
            position absolute
            width auto
            color #fff
            height 16px
            padding 0 5px
            background #f23c3c
            border-radius 8px
            top -6px
            font-size 10px
            line-height 16px
            letter-spacing 0px
            right -9px
        }
    }
}
.knowledgeBase-box{
	position: fixed;
	right:0;
	top: 60px;
	height: 100%;
	width: 349px;
	z-index: 999;
	box-shadow: 0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12);
}
</style>
