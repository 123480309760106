<template lang="pug">
//- NTable 检索样式2
//- TODO: 同原样式：标签超出样式、tooltip
.NTableQueryStyle2
    .query(v-for='i in queryList', :key='i.key', v-loading='i.loading')
        .tit {{ i.label }}
        //- 日期选择
        el-date-picker.form-item.date(
            v-if='i.form === "date"',
            v-model='query[i.key]',
            v-bind='Object.assign(defaultAttrs.date(i), i.attrs)',
            @change='e => $emit("formChange", i, e)'
            @blur='e => $emit("dateBlur", i, e)'
        )
        //- 多选
        el-checkbox-group.form-full.form-item(
            v-else-if='i.form === "checkbox"',
            v-model='query[i.key]',
            v-bind='Object.assign(defaultAttrs.checkbox(i), i.attrs)',
            @change='e => checkboxChange(e, i)'
        )
            transition(name='fade')
                .total-check(v-if='query[i.key].length > 0')
                    .num {{ query[i.key].length }}
                    el-tooltip(content='清空选项')
                        .clear.flex-cc(@click='checkoutClear(i)')
                            i.el-icon-close
            //- TODO: 一个假的按钮，可以删除
            span.checkbox-all(@click='checkAll(i)', :class='{ checked: i.checkAll }') 全部
            el-tooltip(
                v-if='i.tooltip && option.labelDesc',
                v-for='option in i.options',
                :key='option.key + option.label',
                :content='option.labelDesc'
            )
                el-checkbox-button(:label='option.key', :key='option.key + option.label') {{ option.label }}
            el-checkbox-button(v-else, :label='option.key', :key='option.key + option.label') {{ option.label }}
        //- 单选
        el-radio-group.form-full.form-item(
            v-else-if='i.form === "radio"',
            v-model='query[i.key]',
            v-bind='Object.assign(defaultAttrs.radio(i), i.attrs)',
            @change='e => $emit("formChange", i, e)'
        )
            el-radio-button(:label='null') 全部
            el-tooltip(
                v-if='i.tooltip && option.labelDesc',
                v-for='option in i.options',
                :key='option.key + option.label',
                :content='option.labelDesc'
            )
                el-radio-button(:label='option.key', :key='option.key + option.label') {{ option.label }}
            el-radio-button(v-else, :label='option.key', :key='option.key + option.label') {{ option.label }}
        //- 下拉框
        el-select.form-item.form-full(
            v-else-if='i.form === "select"',
            v-bind='Object.assign(defaultAttrs.select(i), style2Default.select(i), i.attrs)',
            v-model='query[i.key]',
            @change='e => $emit("formChange", i, e)',
            @visible-change='v => $emit("querySelectVisibleChange", v, i)',
            @clear='query[i.key] = null'
        )
            el-option(v-for='item in i.options', :key='item.key + item.label', :label='item.label', :value='item.key')
        //- 输入框
        el-input.form-item(
            v-else,
            v-model.trim='query[i.key]',
            v-bind='Object.assign(defaultAttrs.input(i), i.attrs)',
            @keyup.enter.native='e => $emit("search", e, i)'
        )
    slot
</template>

<script>
export default {
    model: {
        prop: 'query',
        event: 'input',
    },
    name: 'NTableQueryStyle2',
    props: ['queryList', 'query', 'defaultAttrs'],
    data() {
        return {
            style2Default: {
                select: () => ({
                    popperClass: 'filter-select-pop',
                }),
            },
        }
    },
    methods: {
        // 多选值变化
        checkboxChange(e, i) {
            // 选中项目时，全部按钮选中状态
            i.checkAll = e.length == 0
            this.$emit('formChange', i, e)
        },
        // 多选 - 点击全部
        checkAll(i) {
            i.checkAll = !i.checkAll
            // 选中全部时，清空多选值
            if (i.checkAll) this.checkoutClear(i)
        },
        // 清空多选值
        checkoutClear(i) {
            this.query[i.key] = []
            i.checkAll = true
            this.$emit('formChange', i, [])
        },
    },
}
</script>

<style scoped lang="stylus">
.NTableQueryStyle2 {
    padding 0 16px
    background #fff
    // ::-webkit-scrollbar {
    //     width 20px
    //     height 20px
    // }
    // ::-webkit-scrollbar-track-piece {
    //     background-color rgba(0, 0, 0, 0)
    //     -webkit-border-radius 2px
    // }
    .query {
        padding 11px 0
        border-bottom 1px dotted #EAEEF2
        display flex
        .tit {
            flex none
            width 125px
            font-weight 500
            color #8492A6
            line-height 35px
            font-size 14px
        }
        .form-item {
            width 400px
        }
        .form-full {
            width 100%
        }
        .el-checkbox-group {
            $x = 36px
            position relative
            line-height 35px
            max-height 35px
            overflow hidden
            transition 0.35s
            will-change max-height
            margin-left - $x
            padding-left $x
            .checkbox-all {
                display inline-block
                color #606266
                font-size 0
                margin-right 14px
                font-size 14px
                border-radius 4px
                padding 7px 4px
                min-width 25px
                line-height 1
                vertical-align middle
                cursor pointer
                transition 0.3s
                &.checked {
                    background #e8f6f5
                    color #15a29e
                    box-shadow none
                }
            }
            .total-check {
                background rgba(21, 162, 158, 0.07)
                border-radius 2px
                font-weight 500
                color #15A29E
                line-height 22px
                min-width 18px
                text-align center
                padding 0 4px
                position absolute
                left 0
                top 6px
                font-size 14px
                box-sizing border-box
                .num {
                    transition 0.35s
                }
                .clear {
                    position absolute
                    left 0
                    top 0
                    bottom 0
                    right 0
                    cursor pointer
                    transform scale(0)
                    transition 0.35s
                    will-change transform
                }
            }
            &:hover {
                max-height 300px
                overflow auto
                .total-check {
                    .num {
                        opacity 0
                        transform scale(0)
                    }
                    .clear {
                        transform scale(1)
                    }
                }
            }
        }
        .el-checkbox-button /deep/ {
            margin-right 14px
            .el-checkbox-button__inner {
                font-size 14px
                border-radius 4px
                padding 7px 4px
                min-width 25px
                border none
                background none
            }
        }
        .el-checkbox-button.is-checked /deep/ {
            .el-checkbox-button__inner {
                background #e8f6f5
                color #15a29e
                box-shadow none
            }
        }
        // el-radio
        .el-radio-group {
            $x = 36px
            position relative
            line-height 35px
            max-height 35px
            overflow hidden
            transition 0.35s
            will-change max-height
            margin-left - $x
            padding-left $x
            &:hover {
                max-height 800px
            }
        }
        .el-radio-button /deep/ {
            margin-right 14px
            .el-radio-button__inner {
                font-size 14px
                border-radius 4px
                padding 7px 4px
                min-width 25px
                border none
                background none
            }
        }
        .el-radio-button.is-active /deep/ {
            .el-radio-button__inner {
                background #e8f6f5
                color #15a29e
                box-shadow none
            }
        }
    }
    .actions {
        display flex
        margin-top 10px
        width 100%
        .query-btn {
            margin-left auto
        }
    }
    // 修改 el-date-picker 原生样式
    .date /deep/ {
        border 0
        .el-input__icon {
            line-height 28px
        }
        .el-range-input {
            text-align left
        }
        .el-range-separator {
            text-align left
            & + .el-range-input {
                text-align center
            }
        }
    }
    .form-item /deep/ {
        // el-input
        .el-input__inner {
            border 0
            font-size 14px
        }
        // el-select
        .el-select__caret {
            display none
        }
    }
}
.fade-enter-active, .fade-leave-active {
    transition transform 0.3s
}
.fade-enter, .fade-leave-to {
    transform scale(0)
}
</style>
