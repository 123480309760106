import Vue from 'vue'
import Router from 'vue-router'
import constantRouterMap from './router.config.js'

// push方法 拦截函数
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(console.error)
}

Vue.use(Router)
const rMap = {}
function findDuplicate(r) {
    if (!rMap[r.name]) rMap[r.name] = [r]
    else {
        rMap[r.name].push(r)
    }
    r.children && r.children.map(i => ((i.father = r.name), findDuplicate(i)))
}
constantRouterMap.map(findDuplicate)

Object.keys(rMap).forEach(name => {
    if (rMap[name].length > 1) console.info('\n\nrouter 配置有问题 \n\n', name, rMap[name])
})

const createRouter = () =>
    new Router({
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return { x: 0, y: 0 }
            }
        },
        routes: constantRouterMap,
    })

const router = createRouter()

/*router.beforeEach((to, from, next) => {
    // 如果是子应用页面，为body增加class
    if (to.path.match(/\/mapp\//)) {
        document.body.classList.add('mapp-page')
    } else {
        document.body.classList.remove('mapp-page')
    }
    // 微应用卸载逻辑
    if (from.path.match('mapp') && (!to.path.match('mapp') || from.path.split('/')[2] != to.path.split('/')[2])) {
        // console.log('从微应用到正常页面，卸载微应用')
        Store.state.app.mapp[from.name]?.unmount()
        Store.commit('app/SET_MAPP', { key: from.name, value: null })
        setTimeout(next)
        return
    }
    next()
})*/

export default router
