<template>
    <div class="data-authority">
        <div class="dA-search-box">
            <el-input
                class="dA-search-input"
                v-model="search"
                size="small"
                placeholder="请输入部门名称"
                @mouseenter.native="inputHover = true"
                @mouseleave.native="inputHover = false">
                <i slot="prefix" :class="['el-input__icon', 'el-icon-' + inputIcon]" @click="clearQuery"></i>
            </el-input>
        </div>
        <div class="dA-tree-box">
            <el-tree
                :data="treeList"
                show-checkbox
                ref="filterDeTree"
                node-key="departmentId"
                :default-expand-all="expandAll"
                @check="departmentTreeCheck"
                :default-checked-keys="defaultDepartmentKeys"
                :filter-node-method="filterNodeData"
                :check-on-click-node="true"
                :expand-on-click-node="false"
                :props="defaultProps">
				<span class="dA-tree-node" slot-scope="{ node, data }">
					<span class="dA-tree-label">{{ node.label }}
						<span v-if="data.dmployees && data.dmployees.length" class="rep-all"
                              @click.stop="() => showRep(node,data)">{{data.isShowEmp ? '显示成员' : '隐藏成员'}}</span>
					</span>
					<div class="tree-rep-box" v-if="!data.isShowEmp">
						<p class="tree-rep" v-for="(item,index) in data.dmployees" :key="index">{{item}}</p>
					</div>
				</span>
            </el-tree>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search: null,
                inputHover: false,
                isShow: false,
                expandAll: true,
                treeList: [],
                defaultDepartmentKeys: [],
                defaultProps: {
                    children: 'childNodes',
                    label: 'name'
                }
            }
        },
        props: {
            defaultData: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        watch: {
            defaultData: {
                handler: function () {
                    this.setDefaultData()
                },
                deep: true
            },
            search(val) {
                this.$refs.filterDeTree.filter(val)
            }
        },
        mounted() {
            this.setDefaultData()
        },
        computed: {
            inputIcon() {
                return this.inputHover ? 'circle-close' : 'search'
            },
        },
        methods: {
            setDefaultData() {
                this.treeList = this.defaultData.departmentList || [];
                if (!this.defaultDepartmentKeys || !this.defaultDepartmentKeys.length) this.defaultDepartmentKeys = this.defaultData.defaultDepartment || []
            },
            clearQuery() {
                if (this.inputIcon === 'circle-close') {
                    this.search = null
                }
            },
            // 是否显示成员
            isShowReq() {
                this.$emit('showReqChange', this.isShow ? 1 : 0)
            },
            departmentTreeCheck(node, data) {
                this.$emit('treeCheck', data.checkedKeys)
            },
            filterNodeData(value, data, node) {
                if (!value) {
                    return true
                }
                let _array = []; //这里使用数组存储 只是为了存储值。
                this.getReturnNode(node, _array, value);
                let result = false;
                _array.forEach(item => {
                    result = result || item
                });
                return result
            },
            getReturnNode(node, _array, value) {
                let isPass = node.data && node.data.name && node.data.name.indexOf(value) !== -1;
                isPass ? _array.push(isPass) : '';
                if (!isPass && node.level != 1 && node.parent) {
                    this.getReturnNode(node.parent, _array, value)
                }
            },
            showRep(node) {
                node.data.isShowEmp = node.data.isShowEmp ? 0 : 1
            }
        }
    }
</script>

<style lang="less" scoped>
    .data-authority {
        height: 378px;
        padding: 9px 0;
        border: 1px solid #eaeef2;

        .dA-search-box {
            display: flex;
            padding: 0 16px;

            .el-checkbox {
                line-height: 32px;
                margin-left: 30px;
            }
        }

        .dA-tree-box {
            margin-top: 20px;
            overflow-y: auto;
            height: calc(100% - 45px);
            padding: 0 16px;
        }

        /deep/ .el-tree-node__content {
            height: auto;
            line-height: 35px;
            align-items: baseline;

            &:hover {
                background: rgba(21, 162, 158, 0.1);
            }

            .tree-rep {
                display: none;
            }

            .dA-tree-label {
                display: flex;
                justify-content: space-between;
            }

            .rep-all {
                color: #15A29E;
                position: absolute;
                right: 5px;
            }

            &.is-expanded .tree-rep {
                display: block;
            }
        }

        /deep/ .is-expanded > .el-tree-node__content .tree-rep {
            display: block;
        }

        /deep/ .is-checked .dA-tree-label {
            color: #15A29E;
        }
    }
</style>
