import { MessageBox } from 'element-ui';
/**
 * Created by WangxinDao on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime (time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
            time = parseInt(time)
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime (time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
    }
}

/**
 * 获取当前url全部参数
 * @return {Object}
 */
 export function getUrlVars() {
    let vars= {},
        hash
    let hashes = window.location.href
        .slice(window.location.href.indexOf('?') + 1)
        .split('&')
    for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=')
        vars[hash[0]] = hash[1]
    }
    return vars
}


/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj (url) {
    const search = url.split('?')[1]
    if (!search) {
        return {}
    }
    return JSON.parse(
        '{"' +
        decodeURIComponent(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
            .replace(/\+/g, ' ') +
        '"}'
    )
}

/** 获取当前时间前后N天前后日期
 * @param {number}
 * @returns {string}
 */

export function getDateStr (AddDayCount) {
    const dd = new Date()
    dd.setDate(dd.getDate() + AddDayCount) // 获取AddDayCount天后的日期
    const y = dd.getFullYear()
    const m = (dd.getMonth() + 1) < 10 ? '0' + (dd.getMonth() + 1) : (dd.getMonth() + 1) // 获取当前月份的日期，不足10补0
    const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() // 获取当前几号，不足10补0
    return y + '-' + m + '-' + d
}
/**
 * 获取url的字段名称
 * @param {*} name
 */
export function getQueryString (name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')

    var r = window.location.search.substr(1).match(reg)
    if (r != null) {
        return unescape(r[2])
    }
    return null
}

/**
 * 重定向到飞书扫码
 */
export function toFeishu () {
    const target = process.env.VUE_APP_MODE;
    // 开发环境   workbench-dev.naxions.com
    // 测试环境   workbench-test.naxions.com
    // 预发布   workbench-pre.naxions.com
    // 生产   vr.naxions.com
    let redirect_uri = encodeURIComponent(window.location.origin + '/#/')
    let url = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a147c5ca807bd00b&redirect_uri=${redirect_uri}&state=`

    console.log("环境：", target, url)
    if (target == 'local') return
    location.href = url;

    document.cookie = "headerList=null;userInfo=null";
    sessionStorage.clear()
    localStorage.setItem('userInfoLogin', null);
}

/*
 * 时间格式化
 * 2020-12-07
 * */
export function dateFormat (value) {
    const d = value ? new Date(value) : new Date()
    const month = d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
    const date = d.getFullYear() + '-' + month + '-' + (d.getDate() > 9 ? d.getDate() : '0' + d.getDate())
    const time = date
    return time
}

/**计算行数
 * @param {dom,number}
 * @returns {number}
 */
export function getLineNum (ref, lineHeight) {
    const height = window.getComputedStyle(ref).height.replace(/px/g, '')
    const line = height / lineHeight

    return line
}


// 验证座机号和手机号，支持分机号
export function regTel (tel) {
    let regexp = /^((0\d{2,3}-?)|)\d{7,8}(|([-\u8f6c]{1}\d{1,4}))$/;
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    return regexp.test(tel) || reg.test(tel)
}

//年月日时分秒
export function allDate () {
    let dateTime
    let yy = new Date().getFullYear()
    let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1):(new Date().getMonth() + 1)
    let dd = new Date().getDate() < 10 ? '0' + new Date().getDate():new Date().getDate()
    let hh = new Date().getHours() < 10 ? '0' + new Date().getHours():new Date().getHours()
    let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes():new Date().getMinutes()
    let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds():new Date().getSeconds()
    dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
    return dateTime
}

// 年月
export function getCurMonth(num){
    let dateTime
    let yy = new Date().getFullYear()
	let curMM = num ? new Date().getMonth() + 1-num : new Date().getMonth() + 1
    let mm = curMM < 10 ? '0' + curMM : curMM
    dateTime = yy + '-' + mm
    return dateTime
}

/**
 * 时间格式化
 *
 * @param {string} fmt 格式化类型
 *
 * 使用:
 * new Date().$format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 */
export function timeFormat(fmt = 'yyyy-MM-dd hh:mm:ss.S') {
    let time = fmt
    const rules = {
        'M+': this.getMonth() + 1,
        'd+': this.getDate(),
        'h+': this.getHours(),
        'm+': this.getMinutes(),
        's+': this.getSeconds(),
        'q+': Math.floor((this.getMonth() + 3) / 3),
        S: this.getMilliseconds(),
    }
    if (/(y+)/.test(time)) {
        time = time.replace(
            RegExp.$1,
            String(this.getFullYear()).substr(4 - RegExp.$1.length)
        )
    }

    Object.keys(rules).forEach(k => {
        if (new RegExp(`(${k})`).test(time)) {
            time = time.replace(
                RegExp.$1,
                RegExp.$1.length === 1
                    ? rules[k]
                    : `00${rules[k]}`.substr(String(rules[k]).length)
            )
        }
    })

    return time
}
/**
 * 安装上面的时间格式化方法
 */
export function  installTimeFormat() {
    if (!Date.prototype.$format) Date.prototype.$format = timeFormat
}
/**
 * 校验手机号 、电话号
 */
// 判断是否为手机号
export function isPoneAvailable(pone) {
    var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(pone)) {
        return false;
    } else {
        return true;
    }
}


//秒转化为年月日-时分秒
export function getDateStrFormat (seconds) {
    let date = new Date(seconds)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    let second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    let currentTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
    return currentTime
}

//获取指定时间的n天前的时间 输出为秒
/*
* day 天
* time 毫秒
* */
export function getBeforeDate(day, time) {
    let curTime = ~~((time / 1000));
    let startDate = curTime - (day * 3600 * 24 );
    return startDate
}

/**
 * 中划线转驼峰
 */
export const transformUpper = str =>
    str && str.replace(/-(\w)/g, ($, $1) => $1.toUpperCase()).replace(/^(.)/, ($, $1) => $1.toUpperCase())

/**
 * 手机号校验
 */
export const isPhone = phone => /^1\d{10}$/.test(phone.toString())

// 解决safari下 new Date('2021-05-06 00:00:00') 报错的问题
export function getSafeDate(date) {
    let tryDate = new Date(date)
    if (isNaN(+tryDate)) {
        return new Date(date.replace(/-/g, '/'))
    } else {
        return tryDate
    }
}
/**
 * 返回指定属性后的对象, 不改变源对象
 * 参考 lodash : https://lodash.com/docs/4.17.15#pick
 * @param {Object} obj 目标对象
 * @param {String[]} keys 指定的对象属性
 * @example
 *
 * var object = { 'a': 1, 'b': '2', 'c': 3 };
 *
 * pick(object, ['a', 'c']);
 * // => { 'a': 1, 'c': 3 }
 */
export function pick(obj, keys) {
    return keys.reduce((acc, cur) => (isValid(obj[cur]) && (acc[cur] = obj[cur]), acc), {})
}

/**
 * 返回忽略属性后的对象, 不改变源对象
 * 参考 lodash : https://lodash.com/docs/4.17.15#omit
 * @param {Object} obj 目标对象
 * @param {String[]} keys 要忽略的对象属性
 * @example
 *
 * var object = { 'a': 1, 'b': '2', 'c': 3 };
 *
 * omit(object, ['a', 'c']);
 * // => { 'b': '2' }
 */

export function omit(obj, keys) {
    return keys.reduce((acc, cur) => (delete acc[cur], acc), { ...obj })
}

/**
 * 有效值
 * @param {any} val 要判断的值
 */
export function isValid(val) {
    return !!val || val === 0
}

/**
 * @description: 弹出二次确认信弹窗
 * @param {String} tip 提示信息
 * @param {Function} confirmFn 确认回调
 * @param {Function} cancelFn 取消回调
 * @param {String} title 提示的标题
 * @param {String} confirmButtonText 确认按钮文案
 * @param {String} cancelButtonText 取消按钮文案
 */
 export function showMessageBox({ tip, confirmFn = () => { }, cancelFn = () => { }, title = '提示', confirmButtonText = '确定', cancelButtonText = '取消' }) {
    MessageBox.confirm(tip, title, {
        confirmButtonText,
        cancelButtonText,
        type: 'warning',
    })
        .then(() => confirmFn())
        .catch(() => cancelFn())
}

/**
 * @description: 校验正整数
 * @param {Number} 要校验的数值
 * @return {Boolean}
 */
 export function isPositiveInteger(num) {
    if (!(/(^[1-9]\d*$)/.test(num))) return false
    return true
}

/**
 * @description: 校验整数
 * @param {Number} 要校验的数值
 * @return {Boolean}
 */
export function isInteger(num) {
    if (!(/(^[0-9]\d*$)/.test(num))) return false
    return true
}

/**
 * 防抖函数
 * 使用方法
 * this.debounce([config,] Function[, params])
 * 如果一个页面多个防抖，请配置参数 key
 */
 export function debounce() {
    const timerData = {}
    return function(...params) {
        let config = {
            wait: 1000,
            flag: true,
            key: 'timer' // 唯一key，
        }
        // 配置参数
        const _config = params.shift()
        // 回调
        let callback = () => { }
        if (hasType('Object', _config)) {
            config = {
                ...config,
                ..._config
            }
            callback = params.shift()
        } else if (hasType('Function', _config)) {
            callback = _config
        } else {
            return console.error('params Not Function')
        }
        const key = config.key || 'timer'
        clearTimeout(timerData[key])
        if (config.flag) {
            const callNow = !timerData[key]
            timerData[key] = setTimeout(function() {
                delete timerData[key]
            }, config.wait)
            if (callNow) {
                callback.apply(this, params)
            }
        } else {
            timerData[key] = setTimeout(() => {
                delete timerData[key]
                callback.apply(this, params)
            }, config.wait)
        }
    }
}
const hasType = (type, param) => {
    return Object.prototype.toString.call(param) === `[object ${type}]`
}
