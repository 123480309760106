<template lang="pug">
//- 开发环境下的菜单配置
.MenuSetting
    .drawer
        //- 检索
        el-select(label='菜单名称', placeholder='系统名称', v-model='sysCode', clearable)
            el-option(
                v-for="item in systemList"
                :key="item.sysCode"
                :label="item.sysName"
                :value="item.sysCode")
        el-input(label='菜单名称', placeholder='菜单名称', v-model='filterName', clearable, @input='filter')
        el-input(label='路径', placeholder='路径', v-model='filterUrl', clearable, @input='filter')
        el-input(label='ID', placeholder='ID', v-model='filterID', clearable, @input='filter')
        el-input(label='图标', placeholder='图标', v-model='filterIcon', clearable, @input='filter')
        el-row
            el-button.action(@click='toggle(false)') 全部收起
            el-button.action(@click='toggle(true)') 全部展开
            el-button.action(@click='addFirstMenu') 添加一级菜单
        //- 所有菜单展示
        .tree-wrapper(v-if="menuTree.data && menuTree.data.length" )
            el-tree(
                ref='tree',
                node-key='id',
                :data='menuTree.data',
                :props='menuTree.props',
                :filter-node-method='filterNode'
            )
                span.custom-tree-node(slot-scope='scope')
                    .label {{ scope.node.label }}
                    .btns
                        el-button(
                            type='text',
                            size='mini',
                            @click.stop='() => { let { childes, ...o } = scope.data; look(o); }'
                        ) 查看
                        el-button(type='text', size='mini', @click.stop='() => edit(scope)') 修改
                        el-button(type='text', size='mini', @click.stop='() => append(scope)') 添加
                        el-button(v-if='!scope.data.childes', type='text', size='mini', @click.stop='() => del(scope)') 删除
            el-button.check-all(@click='look(menuList)') 查看所有菜单配置
            el-button.check-all(@click='copyCurrent(menuList)') 生成并复制当前菜单配置
    el-dialog.add-menu(
        :title='action + addRoute',
        :visible.sync='dialogVisible',
        @closed='onClose',
        :close-on-click-modal='false'
    )
        el-form.form(ref='form', :model='form', label-width='80px')
            el-form-item(label="所属系统" )
                el-select(label='菜单名称', placeholder='系统名称', v-model='sysCode', disabled )
                    el-option(
                        v-for="item in systemList"
                        :key="item.sysCode"
                        :label="item.sysName"
                        :value="item.sysCode")
            el-form-item(
                v-for='item in config',
                :key='item.key',
                :label='item.label',
                :rules='item.rules',
                :prop='item.key'
            )
                el-input(
                    v-if='!item.formType',
                    v-bind='item.formAttrs',
                    v-model='form[item.key]',
                    :placeholder='(item.placeholder || `请输入${item.label}`) + ` (${item.key})`'
                )
                template(v-if='item.formType === `radio`')
                    el-radio(
                        v-model='form[item.key]',
                        v-for='radio in item.options',
                        :key='radio.value',
                        :label='radio.value'
                    ) {{ radio.label }}
                template(v-if='item.formType === `select`')
                    el-select(
                        v-model="form[item.key]",
                        :placeholder='(item.placeholder || `请选择${item.label}`) + ` (${item.key})`'
                    )
                        el-option(
                            v-for="selectItem in item.options",
                            :key="selectItem.value",
                            :label="selectItem.label",
                            :value="selectItem.value"
                        )
        .action
            el-button(@click='$debounce(cancel)') 取消
            el-button(type='primary', @click='$debounce(confirm)') 确认
</template>

<script>
import { config } from './config'
import { execCopy } from '@/utils/common'
import API from './api'
import { getSystemList } from '@/api/sysMng'
import api from './api'
const getFormData = () => ({
    id: '',
    level: '',
    parentId: '',
    url: '',
    name: '',
    iconUrl: '',
    iconUrlAct: '',
    code: '',
    isCommon: false, // 是否公共
    type: 'menu',
    sort: 0,
    serviceUrl: ''
})
export default {
    name: 'MenuSetting',
    data() {
        return {
            sysCode: 'VR',
            systemList: [],
            config,
            action: '',
            filterName: '',
            filterUrl: '',
            filterID: '',
            filterIcon: '',
            // 编辑模式
            editMode: false,
            dialogVisible: false,
            // 在哪里添加菜单
            current: null,
            // 菜单列表
            menuList: null,
            // 菜单树
            menuTree: {
                props: {
                    label: 'name',
                    children: 'childes',
                },
                data: null,
            },
            loading: false,
            dev: process.env.VUE_APP_ENV === 'development',
            drawer: false,
            data: null,
            form: getFormData(),
        }
    },
    computed: {
        addRoute() {
            if (!this.current) return ''
            const nodes = this.getPathNodes(this.current)
            const title = nodes.map(i => i.label).join(' - ')
            return title
        },
    },
    watch: {
        'sysCode': {
            handler(newV){
                if(newV) {
                    this.filterName = ''
                    this.filterUrl = ''
                    this.filterID = ''
                    this.filterIcon= ''
                    this.getMenuList()
                }
            }
        }
    },
    mounted() {
        this.getSystemListFn()
        this.getMenuList()
        this.getTypeOptions()
    },
    methods: {
        getSystemListFn(){
            getSystemList().then(res=>{
                this.systemList = res|| []
            })
        },
        async getTypeOptions() {
            const typeItem = this.config.find(item => item.key === 'type')
            const {data} = await api.getTypeOptions()
            typeItem.options = this.initOptions(data, 'code', 'name')
        },
        initOptions(list, oldVaueKey, oldLabelKey) {
            return list.map(item => {return {'value': item[oldVaueKey], 'label': item[oldLabelKey]}})
        },
        // 生成并复制当前菜单配置
        copyCurrent() {
            const cur = JSON.stringify(this.$authDataTransform(this.menuList))
            execCopy(cur)
        },
        // 收起/展开
        toggle(action) {
            // 获取tree节点的所有数据信息
            const nodeDatas = this.$refs.tree.store.nodesMap
            // 循环所有节点，将需要收起的节点数据的 expanded 属性设置为false
            Object.keys(nodeDatas).forEach(k => (nodeDatas[k].expanded = action))
        },
        // 搜索过滤
        filterNode(value, data) {
            let pass = true
            if (this.filterID) pass = pass && String(data.id)?.match(this.filterID)
            if (this.filterName) pass = pass && data.name?.match(this.filterName)
            if (this.filterUrl) pass = pass && data.url?.match(this.filterUrl)
            if (this.filterIcon) pass = pass && data.iconUrl?.match(this.filterIcon)
            return pass
        },
        filter() {
            this.$refs.tree.filter()
        },
        /**
         * 查看
         */
        look(data) {
            this.$confirm(this.$createElement('pre', JSON.stringify(data, null, 2)), data.name, {
                customClass: 'menu-setting-confirm',
            })
        },
        /**
         * 获取路径上的节点
         * @return {Array} [当前节点, 父节点, 爷爷节点...]
         */
        getPathNodes(node, nodes = []) {
            const parent = node.parent
            if (!parent) return nodes
            nodes.unshift(node)
            return this.getPathNodes(parent, nodes)
        },
        /**
         * 确认添加/修改菜单
         */
        async confirm() {
            await this.$refs.form.validate()
            // 格式处理
            this.form.sort = +this.form.sort
            if (this.editMode) {
                await this.asyncWithLoading(API.updateMenu(this.form), 'loading')
                this.$message.success('修改成功')
            } else {
                this.form = {...this.form, sysCode: this.sysCode}
                await this.asyncWithLoading(API.addMenu(this.form), 'loading')
                this.$message.success('添加成功')
            }
            await this.getMenuList()
            // this.resetHeaderList(false)
            this.cancel()
        },
        cancel() {
            this.current = null
            this.dialogVisible = false
            this.editMode = false
        },
        // 添加、修改菜单弹窗关闭回调
        onClose() {
            this.current = null
            this.action = ''
            this.form = getFormData()
            const config = this.config.find(i => i.key == 'iconUrlAct')
            delete config.formAttrs
            delete config.placeholder
        },
        /**
         * 重新设置菜单 headerList
         */
        resetHeaderList(reload = true) {
            sessionStorage.setItem('headerList', JSON.stringify(this.$authDataTransform(this.menuList)))
            if (!reload) return
            setTimeout(() => {
                window.location.reload()
            }, 100)
        },

        /**
         * 修改菜单
         * @param {Object} scope 节点
         */
        edit(scope) {
            this.action = '修改：'
            const { node, data } = scope
            this.current = node
            this.form = data
            this.dialogVisible = true
            this.editMode = true
        },

        // 添加一级菜单
        addFirstMenu() {
            this.editMode = false
            this.append()
        },
        /**
         * 添加菜单
         */
        append(scope) {
            // 标题
            this.action = '添加：'
            // 添加时关闭选中图片配置，约定使用 iconfont
            const config = this.config.find(i => i.key == 'iconUrlAct')
            config.formAttrs = {
                disabled: true,
            }
            config.placeholder = '请使用 iconfont 配置图标'
            // 没有scope时认为是添加一级菜单
            if (!scope) {
                this.current = null
                // 父级ID
                this.form.parentId = 0
                // 层级
                this.form.level = 1
            } else {
                const { node } = scope
                this.current = node
                // 父级ID
                this.form.parentId = node.data?.id
                // 层级
                this.form.level = node.level + 1
                // 排序
                const sorts = node.data.childes?.map(i => i.sort) || [0]
                this.form.sort = Math.max(...sorts) + 1
            }
            this.dialogVisible = true
        },
        /**
         * 删除菜单
         */
        async del({ data, node }) {
            await this.$confirm(`确定删除 ${node.label}?`)
            await this.asyncWithLoading(API.deleteMenu(data?.id), 'loading')
            this.$message.success('添加成功，正在更新页面')
            await this.asyncWithLoading(this.getMenuList, 'loading')
            // this.resetHeaderList()
        },
        /**
         * 获取当前菜单列表
         * TODO: 获取线上、测试环境菜单
         */
        async getMenuList() {
            this.loading = true
            const { data: list } = await API.menuList(this.sysCode).finally(() => {
                setTimeout(() => {
                    this.loading = false
                }, 500)
            })
            const sort = (a, b) => {
                if (b.childes) b.childes.sort(sort)
                return a.sort - b.sort
            }
            this.menuList = list.sort(sort)
            // 生成菜单渲染树所需数据
            this.menuTree.data = list
        },
        set() {
            this.getMenuList()
            this.drawer = true
            this.loading = true
        },
    },
}
</script>

<style scoped lang="stylus">
.MenuSetting {
    background #fff
    padding 20px 0
    /*position fixed*/
    /*left 0*/
    /*bottom 0*/
    /*z-index 3000*/
    /*cursor pointer*/
    .setting {
        padding 10px
        .el-icon-setting {
            color #15a29e
            font-size 10px
            transition 0.35s
            will-change transform
        }
        &:hover {
            .el-icon-setting {
                transform scale(3) rotate(90deg)
            }
        }
    }
    .add-menu {
        margin-top 20px
        .action {
            display flex
            justify-content center
        }
    }
}
pre {
    max-height 800px
    overflow auto
    line-height 1.3
}
.drawer {
    padding 0 20px 20px
    overflow auto
    .custom-tree-node {
        flex 1
        display flex
        align-items center
        justify-content space-between
        font-size 14px
        padding-right 8px
    }
    .check-all {
        margin-top 20px
    }
    .el-select{
        margin-bottom 10px
        width calc(92% + 10px)
        margin-right 10px
    }
    .el-input {
        margin-bottom 10px
        width 46%
        margin-right 10px
    }
    .action {
        margin-bottom 10px
    }
}
</style>
<style lang='stylus'>
.el-message-box__wrapper .menu-setting-confirm {
    width 600px
}
</style>
