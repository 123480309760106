<template lang="pug">
    .v-menu(:class='[collapse ? "close" : "open"]')
        el-menu(
            ref='menu',
            :default-active='defaultActive',
            :collapse='collapse',
            router
        )
            MenuItem(v-for='item in menuList', :key='item.id', :item='item', :defaultActive='defaultActive')
</template>

<script>
    import {mapState} from 'vuex'
    import MenuItem from './menu-item.vue'

    export default {
        name: 'VMenu',
        components: {MenuItem},
        data() {
            return {
                menu: [],
                menuName: null,
                pathMap: {},
            }
        },
        computed: {
            ...mapState('app', ['collapse', 'menuOpeneds', 'currentModule', 'menuList']),
            defaultActive() {
                return this.$route.path
            },
        }
    }
</script>
<style lang="stylus" scoped>
    .el-menu-item {
        hover()
        &.is-active {
            .iconfont, .tit {
                color #06B4AD
            }
            &::before {
                transform translateX(0)
                opacity 1
            }
        }
    }
</style>
<style lang="stylus">
    .v-menu {
        position fixed
        left 0
        top 60px
        width var(--menu-open-width)
        user-select none
        padding-top 15px
        background #fff
        bottom 0
        transition 0.35s
        z-index 10
        overflow auto
        overflow-x hidden
        .el-menu {
            transition 0.35s
            border-right 0
        }
        &.close {
            padding-bottom 30px
            width var(--menu-close-width)
            .el-menu--collapse {
                width var(--menu-close-width)
            }
        }
        .control {
            position fixed
            bottom 3px
            left 145px
            color #86909C
            width 24px
            height 24px
            cursor pointer
            transition 0.35s
            z-index 2
            &:hover {
                background #F7F8FA
            }
            &.el-icon-s-unfold {
                left 14px
                &::before {
                    position relative
                    z-index 1
                }
                &::after {
                    content ''
                    position absolute
                    left -15px
                    right -7px
                    bottom -3px
                    top -5px
                    background #fff
                }
            }
        }
    }
</style>
