// axios
import request from '@/utils/request'
import {post} from '@/utils/request'

// 获取角色表格数据
export function getRoleList(data) {
    return request({
        url: '/auth-api/role/list',
        data,
        credentials: true
    })
}

// 获取所有权限列表
export function getRoleLeftList(data) {
    return request({
        url: '/auth-api/auth/list',
        data
    })
}

// 获取角色权限树
export function getRoleRightList(data) {
    return post('/auth-api/role/get/by/id', data)
}

// 新增角色
export function addRole(data) {
    return request({
        url: '/auth-api/role/add',
        data
    })
}

// 编辑角色
export function editRole(data) {
    return request({
        url: '/auth-api/role/edit',
        data
    })
}

// 删除角色
export function delRole(data) {
    return request({
        url: '/auth-api/role/delete',
        data
    })
}

// 获取员工账号列表
export function getAccountList(data) {
    return post('/user-api/user/list', data)
}

// 账号详情
export function getAccountDetail(data) {
    return request({
        url: '/user-api/user/get/by/id',
        data
    })
}

// 查询所有角色
export function getAllRole(data) {
    return post('/auth-api/role/search', data)
}

// 编辑账号角色
export function editAccount(data) {
    return post('/auth-api/user/role/edit', data)
}

// 获取城市
export function getCity(data) {
    return post('/user-api/user/city/list', data)
}

// 获取部门
export function getDepartList(data) {
    return post('/user-api/department/list/search', data)
}


// 获取 语音识别-信息关键点列表
export function getVoiceScorePoints(data) {
    return request({
        url: '/quality-inspection-service/quality/detail/listScore',
        data
    })
}

// 获取评分说明列表
export function getScoreExplain(data) {
    return request({
        url: '/quality-inspection-service/quality/detail/listScoreExplain',
        data
    })
}

// 提交语音信息点说明
export function submitScoreExplain(data) {
    return request({
        url: '/quality-inspection-service/quality/detail/saveScoreExplain',
        data
    })
}

// 保存质检人工评分
export function saveQuailtyScore(data) {
    return request({
        url: '/quality-inspection-service/quality/detail/saveScore',
        data
    })
}

// 重置人工评分和说明
export function reSetScoreList(data) {
    return request({
        url: '/quality-inspection-service/quality/detail/resetScore',
        data
    })
}


// 重置人工评分和说明
export function findCallStatic(data) {
    return request({
        url: '/quality-inspection-service/quality/detail/callStat',
        data
    })
}

// 根据组名模糊查询项目组
export function selectGroupByName(data) {
    return post('/quality-inspection-service/quality/selectGroupByName', data)
}

// 根据项目组查询项目，支持模糊查询，不传项目组时查询所有项目
export function selectProjectByGroupIds(data) {
    return post('/quality-inspection-service/quality/selectProjectByGroupIds', data)
}

// 根据项目查询项目下的代表，不传项目时查询所有代表
export function selectMemberInfos(data) {
    return post('/quality-inspection-service/quality/selectMemberInfos', data)
}

// 查询质检员信息
export function selectQcInfos(data) {
    return post('/quality-inspection-service/quality/selectQcInfos', data)
}

// 质检信息列表
export function qualityList(data) {
    return post('/quality-inspection-service/quality/list', data)
}

// 查询医生信息
export function selectHcpInfos(data) {
    return post('/quality-inspection-service/quality/selectHcpInfos', data)
}

// 质检信息状态查询
export function qualityStatus(data) {
    return post('/quality-inspection-service/quality/status', data)
}

// 企业列表
export function tenantList(data) {
    return post('/user-api/tenant/list', data)
}

// 员工账号管理 - 数据权限部门树状结构
export function departmentGetTree(data) {
    return post('/user-api/department/get/tree', data)
}

// 员工账号管理 - 保存用户数据权限
export function departmentSaveDataAuth(data) {
    return post('/user-api/department/save/data/auth', data)
}

// 角色管理 - 岗位列表-下拉框
export function PostList(data) {
    return post('/user-api/xrxs-job/list', data)
}

// 角色管理 - 岗位角色关系保存
export function saveRolePost(data) {
    return post('/user-api/xrxs-job/role-relation/save', data)
}

// 角色管理 - 获取薪人薪事角色关联关系
export function matchRolePost(data) {
    return post('/user-api/xrxs-job/role-relation/list', data)
}

// 角色管理 - 角色下拉列表搜索
export function RoleList(data) {
    return post('/auth-api/role/search', data)
}

//系统枚举列表
export function getSystemList(data) {
    return post('/auth-api/sys-info/select-list', data)
}
