import {getSystemList} from '@/api/sysMng'
export default [
    {
        key: 'name',
        label: '角色名称',
        attrs: {
            width: 250
        },
        query: {
            form: 'input',
            // placeholder: '请选择所属系统',
            attrs: {
                filterable: true
            },
        }
    },
    {
        key: 'sysName',
        label: '所属系统',
        attrs: {
            width: 250
        },
        query: {
            key: 'sysCode',
            label: '所属系统',
            form: 'select',
            attrs: { filterable: true },
            defaultValue: 'VR',
            remoteMethod(sysName = null) {
                return getSystemList({ sysName }).then(list => list.map(i => ({ key: i.sysCode, label: i.sysName })))
            }
        }
    },
    {
        key: 'note',
        label: '描述',
    }
]
