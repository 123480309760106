<template>
    <div class="role-edit">
        <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="800px"
            :before-close="handleClose"
            center
            :modal-append-to-body="false"
        >
            <div v-loading="vLoading">
                <div class="top">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="角色名称" prop="name">
                            <el-input
                                v-model="ruleForm.name"
                                placeholder="请输入：最大长度20个字符，允许中文、英文字母、数字或特殊符号"
                                @blur="nameTrim"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="角色描述" prop="note">
                            <el-input
                                type="textarea"
                                v-model="ruleForm.note"
                                placeholder="请输入"
                                maxlength="200"
                                resize="none"
                                :autosize="{ minRows: 4, maxRows: 4 }"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="所属系统" prop="sysCode">
                            <el-select
                                filterable
                                v-model="ruleForm.sysCode"
                                :disabled="editData.id ? true : false">
                                <el-option
                                    v-for="item in systemList"
                                    :key="item.sysCode"
                                    :label="item.sysName"
                                    :value="item.sysCode">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btm" v-if="ruleForm.sysCode">
                    <div class="tit">为角色赋予权限</div>
                    <tree-transfer
                        ref="wl-tree-transfer"
                        filter
                        high-light
                        default-transfer
                        :mode="mode"
                        :to_data="toData"
                        :from_data="fromData"
                        :defaultProps="defaultProps"
                        node_key="id"
                        height="340px"
                        @removeBtn="remove"
                        @addBtn="add"
                        :title="['全部权限', '已选权限']"
                        :leftSum="leftSum"
                        :rightSum="rightSum"
                        :openAll="true"
                    ></tree-transfer>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="submit('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import * as API from '@/api/sysMng'
import treeTransfer from './component/tree-transfer'
export default {
    props: {
        dialogVisible: {
            // 关闭/打开弹窗
            type: Boolean,
            default: false,
        },
        title: {
            // 弹窗标题
            type: String,
            default: '标题',
        },
        editData: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    components: {
        treeTransfer,
    },
    data() {
        return {
            systemList: [],
            ruleForm: {
                // 表单数据
                id: this.editData?.id,
                name: null,
                note: null,
                sysCode: null
            },
            rules: {
                name: [
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                    { min: 1, max: 20, message: '不能超过 20 个字符', trigger: 'blur' },
                ],
                sysCode: [{ required: true, message: '请输入所属系统', trigger: 'change' }]
            },
            mode: 'transfer', // transfer addressList
            defaultProps: { label: 'name', children: 'childes' },
            fromData: [], // 穿梭框 - 源数据 - 树形
            toData: [], // 穿梭框 - 目标数据 - 树形
            defaultCheckedKeys: [], // 左侧默认选中数据
            toArray: [], // 保存时发送的id合集
            leftSum: 0, // 左侧数据总条数
            rightSum: 0, // 右侧数据总条数
            allData: [], // 全部权限
            vLoading: false,
            screenData: [], // 专门用来筛选右侧数据的
        }
    },
    mounted() {
        console.log(this.editData);
        this.getSystemListFn()
        if(this.editData?.id){
            this.ruleForm = {
                id: this.editData?.id,
                name: this.editData?.name,
                note: this.editData?.note,
                sysCode: this.editData?.sysCode
            }
        }
    },
    watch: {
        'ruleForm.sysCode': {
            handler(newV){
                this.rightSum = 0
                this.toData = []
                this.toArray = []
                if(newV) this.getList()
            }
        }
    },
    methods: {
        getSystemListFn(){
            API.getSystemList().then(res =>{
                this.systemList = res|| []
            })
        },
        getList() {
            // 获取权限列表
            const params = {
                name: '',
                sysCode: this.ruleForm.sysCode
            }
            this.asyncWithLoading(API.getRoleLeftList(params), 'vLoading')
                .then(res => {
                    this.allData = res.data // 请求道的全部权限
                    this.fromData = JSON.parse(JSON.stringify(this.allData)) // 将全部权限赋值给左侧
                    this.screenData = JSON.parse(JSON.stringify(this.allData))
                    this.getLeftSum(this.fromData) // 计算左侧总条数
                    if (this.title === '编辑角色') {
                        // 如果是编辑 获取该角色已有的权限
                        this.getRightList()
                    }
                })
        },
        getRightList() {
            // 获取角色已有的权限 显示在穿梭框右侧
            const param = { id: this.editData.id }
            API.getRoleRightList(param)
                .then(res => {
                    this.ruleForm.name = res.name
                    this.ruleForm.note = res.note
                    this.defaultCheckedKeys = res.authIds // 左侧已选中的数据
                    this.toData = this.getRightItem()
                    // this.fromData = this.getLeftItem()
                    this.getRightSum(this.toData) // 计算右侧数量
                    this.getIdList(this.toData)
                    this.vLoading = false
                })
                .catch(() => {
                    this.vLoading = false
                })
        },
        handleClose() {
            this.$emit('changeDialog', false)
        },
        getRightItem() {
            let rightD = []
            if (this.defaultCheckedKeys) {
                rightD = this.screenData.filter(item => {
                    return this.defaultCheckedKeys.includes(item.id)
                })
            }
            rightD = this.setRightItem(rightD)
            return rightD
        },
        setRightItem(obj) {
            if (obj.length) {
                obj.map(item => {
                    if (item.childes) {
                        item.childes = item.childes.filter(j => {
                            if (this.defaultCheckedKeys.includes(j.id)) {
                                return j
                            }
                        })
                        this.setRightItem(item.childes)
                    } else {
                        if (this.defaultCheckedKeys.includes(item.id)) {
                            return item
                        }
                    }
                })
                return obj
            }
        },
        submit(formName) {
            // 提交编辑信息
            this.$refs[formName].validate(valid => {
                if (valid) {
                    const params = { ...this.ruleForm, authIds: this.toArray }
                    if (this.title === '编辑角色') {
                        API.editRole(params).then(res => {
                            if (res.code === 200) {
                                this.$message({type: 'success', message: '保存成功'})
                                this.$emit('changeDialog', false)
                            } else {
                                this.$message({type: 'error', message: res?.msg})
                            }
                        })
                    } else {
                        API.addRole(params).then(res => {
                            if (res.code === 200) {
                                this.$message({type: 'success', message: '保存成功'})
                                this.$emit('changeDialog', false)
                            } else {
                                this.$message({type: 'error', message: res?.msg})
                            }
                        })
                    }
                }
            })
        },
        // 添加按钮
        add(fromData, toData) {
            this.fromData = JSON.parse(JSON.stringify(this.allData))
            this.leftSum = 0
            this.rightSum = 0
            this.getLeftSum(this.fromData)
            this.getRightSum(toData)
            // this.toData = toData
            this.toArray = []
            this.getIdList(toData)
        },
        // 移除按钮
        remove(fromData, toData) {
            this.leftSum = 0
            this.rightSum = 0
            this.getLeftSum(fromData)
            this.getRightSum(toData)
            // this.toData = toData
            this.toArray = []
            this.getIdList(toData)
        },
        getLeftSum(obj) {
            // 获取左侧数据总数
            if (obj.length !== 0) {
                for (let i = 0; i < obj.length; i++) {
                    if (obj[i].childes && obj[i].childes.length !== 0) {
                        this.getLeftSum(obj[i].childes)
                        this.leftSum = this.leftSum + 1
                    } else {
                        this.leftSum = this.leftSum + 1
                    }
                }
            }
        },
        getRightSum(obj) {
            // 获取右侧数据总数
            if (obj.length !== 0) {
                for (let i = 0; i < obj.length; i++) {
                    if (obj[i].childes && obj[i].childes.length !== 0) {
                        this.getRightSum(obj[i].childes)
                        this.rightSum = this.rightSum + 1
                    } else {
                        this.rightSum = this.rightSum + 1
                    }
                }
            }
        },
        getIdList(obj) {
            // 向后台发送的 权限id集合
            for (let i = 0; i < obj.length; i++) {
                if (obj[i].childes) {
                    this.getIdList(obj[i].childes)
                    this.toArray.push(obj[i].id)
                } else {
                    this.toArray.push(obj[i].id)
                }
            }
        },

        nameTrim() {
            this.ruleForm.name = this.ruleForm.name?.trim()
        },
    },
}
</script>
<style lang="scss" scoped>
.role-edit {
    .tit {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0 !important;
    }
    .top {
        padding-right: 30px;
    }
    .btm {
        padding: 0 30px;
        .tit {
            margin-bottom: 15px;
            color: #576877;
        }
    }
}
</style>
<style lang="scss">
.role-edit {
    .el-dialog__body {
        height: 520px;
        overflow-y: scroll;
        box-sizing: border-box;
    }
    .el-transfer-panel__filter .el-input__inner {
        border-radius: 0;
    }
    .wl-transfer {
        box-sizing: border-box;
        border: 1px solid #eaeef2;
        padding: 5px 10px 10px 10px !important;
        height: 360px !important;
    }
    .transfer-title,
    .transfer-left,
    .transfer-right {
        background: #fff;
        border: none;
    }
    .wl-transfer .transfer-left {
        top: 5px;
        left: 10px;
    }
    .wl-transfer .transfer-right {
        top: 5px;
        right: 10px;
    }
    .transfer-main {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        padding-top: 10px;
        height: 300px;
    }
    .el-transfer-panel {
        border: 1px solid #fff;
        overflow: visible;
        width: 280px;
    }
    .el-transfer-panel:nth-child(3) {
        float: right;
    }
    .el-transfer-panel__filter {
        margin: 0 15px;
    }
    .transfer-center-item {
        overflow: visible;
        padding: 0;
        button {
            margin: 10px 0;
            padding: 0;
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }
        button:nth-child(1) {
            transform: translateY(34px);
        }
        button:nth-child(2) {
            transform: translateY(-34px);
        }
    }
    .el-tree-node__content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
