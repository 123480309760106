const sysMngRouter = {
    path: '/sysMng',
    name: 'sysMng',
    meta: { title: '系统管理', keepAlive: false },
    component:  () => import ('@/views/systemMng/index'),
    redirect: '/sysMng/accountMng',
    children: [{
            path: 'roleMng',
            name: 'role-mng',
            component: () =>
                import ('@/views/systemMng/roleMng/index'),
            meta: { title: '角色管理', keepAlive: false }
        },
        {
            path: 'accountMng',
            name: 'account-mng',
            component: () =>
                import ('@/views/systemMng/accountMng/index'),
            meta: { title: '员工账号管理', keepAlive: false }
        },
        {
            path: 'menuMng',
            name: 'menu-mng',
            component: () =>
                import ('@/views/systemMng/menuMng/index'),
            meta: { title: '菜单管理', keepAlive: false }
        },
    ]
}
export default sysMngRouter
