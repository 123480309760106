<template lang='pug'>
#app
    keep-alive
        APPLayout
</template>
<script>
import '@/assets/css/index.scss'
import APPLayout from '@/layout/APPLayout'
export default {
    name: 'App',
    components: { APPLayout },
}
</script>
<style lang="scss">
html,
body,
#app {
    height: 100%;
}
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>
