import request from '@/utils/request'

export default {
    // 查询所有菜单
    menuList(sysCode) {
        return request({ method: 'get', url: `/auth-api/auth/menu/list?sysCode=${sysCode}` })
    },
    // 添加菜单
    addMenu(data) {
        return request({ url: '/auth-api/auth/menu/add', data })
    },
    // 修改菜单
    updateMenu(data) {
        return request({ url: '/auth-api/auth/menu/update', data })
    },
    // 删除菜单
    deleteMenu(id) {
        return request({ url: `/auth-api/auth/menu/delete/${id}` })
    },
    // 类型下拉options
    getTypeOptions() {
        return request({ url: '/auth-api/auth/type/list'})
    }
}
