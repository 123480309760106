import Vue from 'vue'
import axios from 'axios'
import { execCopy } from '@/utils/common'
import { toFeishu } from './index'
import { Message } from 'element-ui'
const UUID = require('uuidjs')

axios.defaults.withCredentials = true
const service = axios.create({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true,
    timeout: 300000,
    errorToast: true,
})
// http request 拦截器
service.interceptors.request.use(
    config => {
        // 用于计算接口耗时
        config.startTime = +new Date()
        // 获取token
        const token = localStorage.getItem('token')
        // 添加traceId
        const uuid = UUID.parse(UUID.generate())
        config.headers.traceId = uuid?.hexFields?.node
        // 添加token到headers
        if (token) {
            config.headers.token = token
        }
        config.headers.larkAppId = 'cli_a147c5ca807bd00b'
        // 鉴权参数设置
        if (config.method === 'get') {
            // get请求下 参数在params中，其他请求在data中
            config.params = config.params || {}
        } else {
            config.data = config.data ?? {}
        }
        return config
    },
    err => {
        console.log('request err')
        handleError(err)
        return Promise.reject(err)
    }
)
// response拦截器
service.interceptors.response.use(
    response => {
        const { status, data, config } = response
        // 兼容导出数据情况
        if (status === 200 && config.responseType == 'arraybuffer') {
            const enc = new TextDecoder('utf-8')
            let jsonData
            try {
                // 只有返回错误时，才能parse，正常返回二进制文件时 jsonData 是 undefined
                jsonData = JSON.parse(enc.decode(new Uint8Array(data)))
                response.data = jsonData
            } catch (error) {
                console.log(error)
            }
            if (jsonData?.code && jsonData?.code != 200) {
                handleError({ ...response, response, config })
                return Promise.reject(data)
            }
            return response
        }
        if (status === 200 && data?.code == 200) {
            return data
        } else {
            console.log('response reject')
            handleError({ ...response, response, config })
            return Promise.reject(data)
        }
    },
    error => {
        console.log('response error')
        if (axios.isCancel(error)) {
            // 中断promise链接
            return new Promise(() => {})
        }
        handleError(error)
        if (error.response) {
            const { status, data } = error.response
            if ((data.code && data.code == 9999) || status === 401) {
                toFeishu()
            }
        }
        return Promise.reject(error)
    }
)

/**
 * 报错提示
 * @param {Object} error 报错对象
 */
function handleError(error) {
    console.log('request.js handleError', { error })
    if (error.handled) return
    error.handled = true
    const _err = error.response
    // 报错信息
    const errorMsg = _err?.data?.msg || _err?.data?.message || error.message || '请求异常'
    // 错误码
    const code = _err?.data?.code ?? _err?.status
    // id
    const headers = error.config.headers
    // 弹窗报错
    errorToast(
        `${error.config.url}: [${code || code == 0 ? code + '-' : ''}${headers.traceId}] ${errorMsg}`,
        error,
        headers?.traceId
    )
    // 错误日志上报
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, config = {}) {
    return service.post(url, data, config).then(response => response.data)
}

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        service
            .get(url, { params })
            .then(response => {
                if (response && (response.code === 200 || response.code === '200')) {
                    // 返回成功处理  这里传的啥 后续调用的时候 res就是啥
                    resolve(response.data)
                } else {
                    // resolve(response.data)
                    // 错误处理
                    reject(response)

                    Message.error(response.message || response.msg || '请求异常')
                }
            })
            .catch(err => {
                let message = '请求失败！请检查网络'
                if (err.response) message = err.response.data.msg || err.response.data.message
                Message.error(message || '请求异常')
                reject(err)
            })
    })
}
export default service

// 报错弹窗
function errorToast(errorMsg, { config, response }) {
    let msg = {
        request: {
            url: config.baseURL + config.url,
            headers: config.headers,
            data: config.data,
        },
        response: {
            headers: response?.headers,
            data: response?.data,
        },
    }
    const instance = new Vue()
    const h = instance.$createElement
    Message.error({
        message: h('div', null, [
            h(
                'span',
                {
                    style: {
                        fontSize: '12px',
                    },
                },
                errorMsg
            ),
            h(
                'span',
                {
                    on: {
                        click() {
                            const target = JSON.stringify(msg)
                            if (execCopy(encodeURIComponent(target))) {
                                Message.success('报错信息复制成功')
                            }
                        },
                    },
                    style: {
                        fontSize: '12px',
                        marginLeft: '5px',
                        color: '#888',
                        cursor: 'pointer',
                    },
                },
                '复制'
            ),
        ]),
    })
}
