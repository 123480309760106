/**
 * 复制内容
 * @param {String} content 要复制的内容
 */
export function execCopy(content) {
    if (!document.execCommand('copy')) {
        console.error('[@/utils/common.js] copy: 复制失败')
        return false
    }
    let transfer = document.createElement('input')
    transfer.style.position = 'fixed'
    document.body.appendChild(transfer)
    transfer.value = content
    transfer.focus()
    transfer.select()
    document.execCommand('copy')
    transfer.blur()
    document.body.removeChild(transfer)
    return true
}
