// 兼容 IE
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './utils/vue.proto'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import '@/assets/css/theme/index.css'
import '@/assets/css/project.styl'
import Cookies from 'js-cookie'
import {debounce} from './utils'
Vue.prototype.$cookie = Cookies
Vue.prototype.$debounce = debounce()
Vue.use(Element,{size: 'small'})

Vue.config.productionTip = false

// window.STORE = store
console.log(process.env)

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
