<template lang="pug">
.role-content
    n-table(
        ref='table',
        v-loading='nLoading',
        :columns='columns',
        :list='list',
        :pagination='pageOption',
        :searchOnSelected='false',
        borderTable,
        @search='search',
        @size-change='sizeChange',
        @current-page-change='currentChange'
    )
        el-button(slot='query-btn', type='primary', icon='el-icon-plus', @click="add") 新建
        template(v-slot:table-footer)
            el-table-column(label='操作', width="200px", align="center")
                template(#default="scope")
                    el-button(type='text' @click="edit(scope.row)") 编辑
                    el-button(type='text' style="color: #f05e6e" @click="del(scope.row)") 删除
    add-or-edit(v-if="dialogVisible" :dialogVisible="dialogVisible" @changeDialog="editChange" :title="dialogTitle" :editData="editData")
</template>
<script>
import * as API from '@/api/sysMng'
import NTable from '@/components/NTable'
import pagination from '@/mixins/pagination'
import addOrEdit from './addOrEdit'
import columns from './columns'
export default {
    components: {
        addOrEdit,
        NTable
    },
    mixins: [pagination('getList')],
    data() {
        return {
            nLoading: false,
            list: [],
            columns,
            dialogVisible: false, // 编辑/确认
            dialogTitle: null, // 编辑/确认弹窗标题
            editData: null, // 编辑时传入子组件的数据
            showMatch: false,
            rolePostData: {
                match: [],
                postList: [],
                roleList: [],
            },
        }
    },
    methods: {
        getList() {
            const query = this.$refs.table.getSearchData()
            const body = {
                page: this.pageOption.page,
                pageSize: this.pageOption.pageSize
            }
            if (query) Object.assign(body, query)
            this.asyncWithLoading(API.getRoleList(body)).then(res => {
                this.list = res?.data?.content
                this.pageOption.totalElements = res?.data?.totalElements
            })
        },
        search() {
            this.pageOption.page = 1
            this.getList()
        },
        add(){
            this.editData =  { id: null, roleName: '', describe: '' }
            this.dialogTitle = '新建角色'
            this.dialogVisible = true
        },
        edit(row) {
            this.editData = row
            this.dialogTitle = '编辑角色'
            this.dialogVisible = true
        },
        editChange(val) {
            // 关闭编辑弹窗
            this.dialogVisible = this.showMatch = val
            this.getList()
        },
        del(row) {
            // 点击删除按钮
            this.$confirm('确认删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
            }).then(() => {
                this.confirmDel(row.id)
            })
        },
        confirmDel(id) {
            // 确认删除
            const params = { id: id }
            API.delRole(params).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                })
                this.getList()
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.role-content {
    .screen-container {
        float: left;
    }
    .del {
        color: #f05e6e;
    }
    .btn-box {
        float: right;
        margin-bottom: 13px;
        button {
            border: 1px solid #15a29e;
            color: #15a29e;
        }
    }
}
</style>
<style lang="scss">
.role-content {
    .has-gutter .cell {
        font-weight: bold;
    }
}
</style>
