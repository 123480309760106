/**
 * 分页功能，配合 n-table 使用，简化开发。支持缓存分页大小配置。
 * 实际应用的页面包含但不限于：@/views/wechat 中的log、channelCode、batchSending
 *
 * @param {String} getDataFnKey 获取表格数据的异步请求方法名
 * @param {Boolean} immediately  是否立即执行 getDataFnKey 方法
 * @return {Function} mixin
 *
 */
export default (getDataFnKey = 'getList', immediately = true) => {
    if (!getDataFnKey) throw new Error('请传入请求方法名')
    return {
        data() {
            return {
                pageOption: {
                    page: 1,
                    pageSize: 10
                }
            }
        },
        methods: {
            currentChange(page) {
                this.pageOption.page = page
                this[getDataFnKey]()
            },
            sizeChange(v) {
                this.pageOption.pageSize = v
                localStorage.setItem(`${this.$route.name}-pageSize`, v)
                this[getDataFnKey]()
            },
        },
        mounted() {
            const pageSize = +localStorage.getItem(`${this.$route.name}-pageSize`)
            if (pageSize) this.pageOption.pageSize = pageSize
            immediately && this[getDataFnKey]()
        }
    }
}
