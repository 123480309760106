<template>
    <div class="account-content">
        <n-table
            ref='table'
            v-loading='nLoading'
            :columns='columns'
            :list='list'
            :pagination='pageOption'
            :searchOnSelected='false'
            borderTable
            @search='search'
            @size-change='sizeChange'
            @current-page-change='currentChange'
        >
            <template v-slot:table-footer>
                <el-table-column label='操作' width="150px" align="center">
                    <template #default="scope">
                        <el-button type='text' @click="handleClick(scope.row.id)">编辑</el-button>
                    </template>
                </el-table-column>
            </template>
        </n-table>
        <edit v-if="dialogVisible" :dialogVisible="dialogVisible" @changeDialog="editChange" :id="userId"></edit>
    </div>
</template>
<script>
    import * as API from '@/api/sysMng'
    import edit from './edit'
    import NTable from '@/components/NTable'
    import pagination from '@/mixins/pagination'
    import columns from './columns'
    export default {
        components: {
            edit,
            NTable
        },
        mixins: [pagination('getList')],
        data() {
            return {
                nLoading: false,
                columns,
                list: [],
                dialogVisible: false, // 编辑弹窗
                userId: null, // 编辑的id
            }
        },
        methods: {
            search() {
                this.pageOption.page = 1
                this.getList()
            },
            handleClick(id) {
                this.userId = id
                this.dialogVisible = true
            },
            getList() {
                const params = {
                    page: this.pageOption.page,
                    pageSize: this.pageOption.pageSize,
                    depart: true,
                    ...this.$refs.table.getSearchData(),
                }
                this.asyncWithLoading(API.getAccountList(params)).then(res => {
                    this.list = res.content
                    for (let i = 0; i < this.list.length; i++) {
                        this.list[i].tenantName = this.list[i].tenantInfoVo.tenantName
                    }
                    this.pageOption.totalElements = res?.totalElements
                })
            },
            editChange() {
                // 关闭弹窗
                this.dialogVisible = false
                this.getList()
            },
        },
    }
</script>
<style lang="scss" scoped>
    .account-content {
        .account-search {
            margin-bottom: 20px;
        }
        .screen-container {
            margin-bottom: 10px;
        }
        .del {
            color: #f05e6e;
        }
    }
</style>
<style lang="scss">
    .account-content {
        .has-gutter .cell {
            font-weight: bold;
        }
        .el-table .el-table__row td .cell,
        .el-table .el-table__row td .cell p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>
