<template>
    <div>
        <MenuSetting/>
    </div>
</template>

<script>
import MenuSetting from '@/components/MenuSetting/MenuSetting'
export default {
    name: "index",
    components:{
        MenuSetting
    }
}
</script>

<style scoped>

</style>
