
const state = {
    userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null
}
const mutations = {
    SET_USER_INFO(state, payload) {
        state.userInfo = payload
    }
}
const actions = {
    setUserInfo({ commit }, payload) {
        commit('SET_USER_INFO', payload)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
