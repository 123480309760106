const state = {
    userInfo: null,
    // 菜单列表
    menuList: [],
    // 当前一级菜单
    currentModule: '/',
    // 开启的状态
    menuOpeneds: {},
    mapp: {},
    // 菜单收起状态
    collapse: false,
}

const mutations = {
    SET_MAPP(state, { key, value }) {
        state.mapp[key] = value
    },
    SET_MENU_OPENEDS(state, { key, value }) {
        state.menuOpeneds[key] = value
    },
    SET_CURRENT_MODULE(state, value) {
        state.currentModule = value
    },
    SET_MENU_LIST(state, value) {
        if (!value || !Array.isArray(value)) return
        const list = value.map(item => ({
            ...item,
            label: item.name,
            key: item.url,
        }))
        state.menuList = list
        // 兼容老版本
        window.sessionStorage.setItem('headerList', JSON.stringify(list))
    },
    SET_COLLAPSE(state, value) {
        state.collapse = value
    },
}
const actions = {}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
